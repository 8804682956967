import React from 'react'
import "./Privacy.css"


function Privacy() {
    const myPolicy = 15304757;
    return (
        <div className="privacy-container">
            <h2>Privacy Policy</h2>

            <iframe 
                    src="https://www.iubenda.com/privacy-policy/15304757/legal"
                    width="100%" 
                    height="8600px"
                    scrolling="no"
                >    
            </iframe>
{/* 
            <a href="https://www.iubenda.com/privacy-policy/15304757" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
            <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script> */}
        </div>
    )
}

export default Privacy
