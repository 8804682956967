import React, { useState, useEffect } from 'react'
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageList from '@material-ui/core/ImageList';
import { Lightbox } from "react-modal-image";


import "./Satelliti.css"
import ReactGA from 'react-ga4';
import { CircularProgress } from '@material-ui/core';
import Requests from '../../scripts/Requests';

function Satelliti() {
    const [openLightbox, setOpenLightBox] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const [connectionError, setConnectionError] = useState(false)

    const [satellites, setSatellites] = useState([])
    const imageLoadingStatus = []

    const closeLightbox = () => {
        setOpenLightBox(!openLightbox);
    };

    const _imageClick = (key) => {
        setOpenLightBox(!openLightbox);
        setImageIndex(key);
        console.log(key);
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Satelliti" });

        /*
        axios.get(process.env.REACT_APP_BACKEND + "/satelliti")
            .then(res => JSON.parse(Crypto.decode(res.data)))
            .then(webcam => {
                setSatellites(webcam)
                for (let i = 0; i < webcam.length; i++) {
                    imageLoadingStatus.push(false);
                }
            })
            .catch(err => {
                console.log(err);
                //if (!connectionError) setConnectionError(prev => !prev);
            });
        */

        Requests.get_satellites()
        .then(res => {
            setSatellites(res)
        })
        .catch(err => {
            console.log(err);
            if (!connectionError) setConnectionError(prev => !prev);
        });


        if (window.innerWidth < 960) setIsMobile(true);
        window.addEventListener('resize', _handleResize);
        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);

    const _handleResize = () => {
        if (window.innerWidth < 960) setIsMobile(true);
        else setIsMobile(false);
    }

    const _handleImageLoading = (i) => {
        imageLoadingStatus[i] = true;
    }

    return (
        <div className="satellite-wrapper">
            <div className="satellite-header">
                <h1>SATELLITI</h1>
            </div>
            <div className="satellite-body">
                {satellites.length > 0 &&
                    <ImageList cols={isMobile ? 1 : 3} rowHeight="auto" gap={5} className="satellite-image-grid">
                        {satellites.map((item, key) =>
                        (
                            <ImageListItem key={item.id} >
                                <img
                                    src={item.url}
                                    alt={imageLoadingStatus[key] ? item.name : ""}
                                    onClick={() => _imageClick(key)}
                                    style={{ cursor: "pointer" }}
                                    onLoadedData={_handleImageLoading(key)}
                                />
                                {
                                    !imageLoadingStatus[key]  &&  <CircularProgress color='primary' />
                                }
                            </ImageListItem>
                        )
                        )}

                    </ImageList>
                }

            </div>

            {
                openLightbox &&
                <Lightbox
                    medium={satellites[imageIndex].url}
                    alt={satellites[imageIndex].name}
                    onClose={closeLightbox}
                />
            }
        </div >
    )
}

/*
const itemData = [
    {
        img: '/sat_images?url=http%3A%2F%2Fsat24.com%2Fzoomimage2.ashx%3Fregion%3Daf%26index%3D1%26ir%3DTrue%26lat%3D38%26lon%3D14',
        title: 'Sat24 (SUD ITALIA)',
    },
    {
        img: 'https://www.meteo60.fr/satellites/animation-satellite-ir-france.gif',
        title: 'MeteoSat (Infrarossi + Top Nubi)',
    },
    {
        img: 'https://api.sat24.com/animated/EU/infraPolair/3/Central%20European%20Standard%20Time/9651222',
        title: 'Sat24 (Infrarossi)',
    },
    {
        img: "https://api.sat24.com/animated/EU/visual/3/Central%20European%20Standard%20Time/5815130",
        title: 'Sat24 (Clouds)',
    },
    {
        img: "https://api.sat24.com/animated/EU/snow/3/Central%20European%20Standard%20Time/5191996",
        title: "Sat24 (Neve)"
    },
    {
        img: "https://www.meteosatonline.it/anim/anim.php",
        title: "Aemet (Masse d'aria)"
    },
    {
        img: '/sat_images?url=http://zoz.cbk.waw.pl/images/stories/snow/mapa_duza.png',
        title: "Snowcover"
    },
    {
        img: '/sat_images?url=http://meteocentre.com/analysis/local/eur.gif',
        title: "Sinottica"
    },
    {
        img: "https://images.lightningmaps.org/blitzortung/europe/index.php?map=0",
        title: "Fulmini"
    }
];
*/
export default Satelliti
