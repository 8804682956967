//export const KEY=process.env.REACT_APP_DECODE_KEY; 

export class Crypto{
    static _v=73;

    static decode(hash){
        return window.atob(hash.substring(0, this._v)+hash.substring(this._v+this.countDigits(this._v)));
    }

    static decodeWithKey(hash, key){
        return window.atob(hash.substring(0, key)+hash.substring(key+this.countDigits(key)));
    }

    static decodeBase64(str){
        return JSON.parse(window.atob(str));
    }

    static countDigits(n){return n.toString().length;}
}

