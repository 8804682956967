import React, { useState, useEffect, useRef } from 'react'
import { Link, NavLink, useLocation, matchPath, useParams } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import $ from 'jquery'

import './Navbar.css'
import { Button } from './Button'

function Navbar() {
    const scrollBar = useRef()

    const location = useLocation();
    const params = useParams()
    const [isMobile, setIsMobile] = useState(false);

    const [click, setClick] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [navbarBlock, setNavbarBlock] = useState(false);


    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    //HOVER STATUS
    const [nowcastingHover, setNowcastingHover] = useState(false);

    useEffect(() => {
        if (!location.pathname.includes("mappa")) setNavbarBlock(true);

        if (window.innerWidth < 960) setIsMobile(true);
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (location.pathname.includes("mappa")) {
            setNavbarBlock(prev => !prev);
        } else {
            setNavbarBlock(false);
        }
        return () => { }
    }, [location]);

    const handleScroll = () => {
        //SET SCROLL LENGTH BAR
        if(!location.pathname.includes("mappa") && scrollBar.current){
            if (window.scrollY > 50) {
                if (isMobile && window.screenY > 50 && scrollBar.current) {
                    scrollBar.current.style.width = getScrollPercent() + "%"
                } else {
                    scrollBar.current.style.width = getScrollPercent() + "%"
                }
            } else {
                scrollBar.current.style.width = 0
            }
        }

        //SET IF SCROLLED
        if (window.scrollY > 0) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }

    const handleResize = () => {
        if (window.innerWidth < 960) setIsMobile(true);
        else setIsMobile(false);
    }

    const getScrollPercent = () => {
        var h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
    }

    return (
        <nav className={scrolled && !navbarBlock ? "navbar scrolled" : "navbar"}>
            <div className={scrolled && !navbarBlock ? "navbar-container sticky-navbar" : "navbar-container"}
            >
                <Link to="/" className={navbarBlock ? "navbar-logo mini-logo" : "navbar-logo"}>
                    <img src={navbarBlock ? "/img/logo_192.png" : (scrolled ? "/img/logo_192.png" : "/img/logo.png")} alt="logo" />
                </Link>
                {/*
                !isMobile &&
                    <span className="social-icons">
                        <SocialIcon url={process.env.REACT_APP_FACEBOOK} network="facebook" fgColor="#fff" style={{ height: 32, width: 32 }} />
                        <SocialIcon url={process.env.REACT_APP_INSTAGRAM} network="instagram" fgColor="#fff" style={{ height: 32, width: 32 }} />
                    </span>
                */}
                <Button 
                    className={"report-link"}
                    buttonSize={"btn--small"} 
                    buttonStyle={"btn--primary"}
                    path={"/report"}
                >
                    <i className='fa fa-bell'></i>Segnalazioni
                </Button>
                
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
                <ul className={isMobile ? (click ? "nav-menu active" : "nav-menu") + (scrolled || navbarBlock ? " mobile-scrolled" : "") : (click ? "nav-menu active" : "nav-menu")}>
                    <li className="nav-item">
                        <NavLink exact className="nav-links" to="/" onClick={closeMobileMenu} activeClassName="this">
                            Home
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact className="nav-links" to="/chi-siamo" onClick={closeMobileMenu} activeClassName="this">
                            Chi Siamo
                        </NavLink>
                    </li>
                    {/*
                        isMobile &&
                        <li className="nav-item">
                            <NavLink exact className="nav-links" to="/nowcasting" onClick={closeMobileMenu} activeClassName="this">
                                Stazioni
                            </NavLink>
                        </li>
                    */
                    }
                    <li className="nav-item">
                        {!isMobile &&
                            <NavLink exact className="nav-links" to="/nowcasting"
                                onClick={closeMobileMenu}
                                onMouseEnter={() => setNowcastingHover(true)}
                                onMouseLeave={() => setNowcastingHover(false)}
                                activeClassName="this"
                            >
                                Nowcasting <i className="fas fa-chevron-down" style={{ marginLeft: 10, marginTop: 5 }}></i>
                            </NavLink>
                        }

                        {
                            isMobile &&
                            <a className="nav-links-mobile" onClick={() => setNowcastingHover(!nowcastingHover)}>
                                Nowcasting
                                <i className={nowcastingHover ? "fas fa-chevron-up" : "fas fa-chevron-down"} style={{ marginLeft: 10, marginTop: 5 }}></i>
                            </a>
                        }

                        {nowcastingHover &&
                            <ul className={(scrolled ? "subnav-container-mini" : "subnav-container") + " nowcasting-menu"} style={{ top: !isMobile ? ((scrolled || navbarBlock) ? "60px" : "108px") : "" }}>
                                <li className="subnav-item" onMouseEnter={() => setNowcastingHover(true)} onMouseLeave={() => setNowcastingHover(false)}>
                                    <NavLink exact className="nav-links-sub" to="/nowcasting/" onClick={closeMobileMenu} activeClassName="this">
                                        Stazioni
                                    </NavLink>
                                </li>
                                { /*
                                <li className="subnav-item" onMouseEnter={() => setNowcastingHover(true)} onMouseLeave={() => setNowcastingHover(false)}>
                                    <NavLink exact className="nav-links-sub" to="/nowcasting/nivometria/" onClick={closeMobileMenu} activeClassName="this">
                                        Nivometria
                                    </NavLink>
                                </li>
                                */}
                                {
                                <li className="subnav-item" onMouseEnter={() => setNowcastingHover(true)} onMouseLeave={() => setNowcastingHover(false)}>
                                    <NavLink exact className="nav-links-sub" to="/nowcasting/radar" onClick={closeMobileMenu} activeClassName="this">
                                        Radar
                                    </NavLink>
                                </li>
                                }
                                <li className="subnav-item" onMouseEnter={() => setNowcastingHover(true)} onMouseLeave={() => setNowcastingHover(false)}>
                                    <NavLink exact className="nav-links-sub" to="/nowcasting/satelliti" onClick={closeMobileMenu} activeClassName="this">
                                        Satelliti
                                    </NavLink>
                                </li>
                                <li className="subnav-item" onMouseEnter={() => setNowcastingHover(true)} onMouseLeave={() => setNowcastingHover(false)}>
                                    <NavLink exact className="nav-links-sub" to="/nowcasting/webcam" onClick={closeMobileMenu} activeClassName="this">
                                        Webcam
                                    </NavLink>
                                </li>
                            </ul>
                        }
                    </li>
                    <li className="nav-item">
                        <NavLink exact className="nav-links" to="/mappa" onClick={closeMobileMenu} activeClassName="this">
                            Mappa
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact className="nav-links" to="/contatti" onClick={closeMobileMenu} activeClassName="this">
                            Contatti
                        </NavLink>
                    </li>
                    <li className="nav-item join">
                        <NavLink exact className="nav-links join" to="/aderisci" onClick={closeMobileMenu} activeClassName="aderisci">
                            ADERISCI
                        </NavLink>
                    </li>
                </ul>

                
            </div>
            
            {!location.pathname.includes("mappa") && <div id='scroll-loading' ref={scrollBar}></div>}
        </nav>
    )
}

export default Navbar
