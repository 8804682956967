import React, { useEffect } from 'react'

import './ChiSiamo.css';
import Parallax from '../components/Parallax';
import ReactGA from 'react-ga4';

function ChiSiamo() {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Chi siamo" });
        return null;
    }, []);

    return (
        <div className="chisiamo-container">
            {/*<Parallax img="../img/chi-siamo/chisiamo0.jpg" position="center"/>*/}

            <img src="../img/chi-siamo/chisiamo.jpg" id="chisiamo-cover"/>

            <div className='chisiamo-row'>
                <div className="content">

                    {/*<img src="./img/logo.png" />*/}
                    <h2>Calabria Weather Data (CWD): cos’è e perché nasce?</h2>
                    <p>
                        CWD è un progetto basato sul monitoraggio meteorologico in tempo reale, sulle analisi climatiche su scala regionale e sulla sensibilizzazione alle tematiche ambientali, con uno sguardo alla valorizzazione e alla promozione dei luoghi di interesse turistico e naturalistico sul nostro territorio.
                        L’idea, interamente” Made in Calabria”, nasce nel 2020 e viene attuata ad inizio 2021 allo scopo di potenziare la quantità e migliorare la qualità dei dati meteo messi a disposizione degli utenti, agevolando le osservazioni in real-time e la realizzazione di statistiche e approfondimenti.

                    </p>

                </div>

                <Parallax img="../img/chi-siamo/chisiamo1.jpg" position="center" />
            </div>

            <div className='chisiamo-row column-reversed-mobile' >
                <Parallax img="../img/chi-siamo/chisiamo2.jpg" position="center" />
                <div className="content">
                    <h2>Cosa fa CWD?</h2>
                    <p>
                        CWD usufruisce di stazioni meteo amatoriali, semi-professionali e professionali dislocate sul suolo regionale e gli immediati dintorni ed installate secondo le norme OMM (WMO). I valori registrati dalle varie strumentazioni possono essere dunque consultati con una comoda visione d’insieme, comparandoli tra loro, sulla nostra piattaforma attraverso la LiveMap, la LiveTab e le varie Station Page.
                        L’utilizzo di diverse webcam, anche di luoghi a vocazione turistica, di satelliti e di radar completa il monitoraggio a tutto tondo delle condizioni in tempo reale.
                        Il nostro impegno nella community  trova una finestra da cui affacciarsi sui nostri canali social, dove portiamo in evidenza particolari condizioni meteo in atto, segnalazioni da parte degli utenti, amarcord, analisi giornaliere e periodiche accompagnate da grafiche che ne agevolano la comprensione, ma anche spunti paesaggistici, turistici e naturalistici che permettono alle bellezze presenti in Calabria di essere apprezzate da tutti.
                    </p>
                </div>


            </div>

            <div className='chisiamo-row'>
                <div className="content">
                    <h2>Gli obiettivi di CWD</h2>
                    <p>
                        Tra gli obiettivi del progetto c’è sicuramente quello di puntare, col tempo, a definire quanti più dettagli microclimatici possibili su un numero sempre maggiore di aree. Tale target dovrà fare da leva su almeno altre tre finalità:
                        <br />1) sensibilizzare gli utenti alle caratteristiche climatiche della propria zona e a come i cambiamenti climatici interferiscono, in maniera più o meno impattante;
                        <br />2) indirizzare gli utenti verso l’utilizzo di strumenti validi ai fini scientifici, per una corretta informazione e divulgazione di dati e notizie;
                        <br />3) stimolare gli utenti a divenire parte attiva del progetto, anche nel quotidiano, con contributi che vanno dalla semplice segnalazione delle condizioni meteo al monitoraggio in prima persona, attraverso l’installazione di una stazione o/e webcam.

                    </p>
                </div>

                <Parallax img="../img/chi-siamo/chisiamo3.jpg" position="center" />
            </div>

        </div>
    )
}

export default ChiSiamo
