import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import ReactGA from 'react-ga4'

import "./Webcam.css"
import ErrorBanner from '../../components/ErrorBanner'
import { TextField } from '@mui/material'
import { Button } from '../../components/Button'
import Requests from '../../scripts/Requests'


const provinceSlug = ["cs", "kr", "cz", "vv", "rc"];

function Webcam() {

    const [search, setSearch] = useState(null);
    const [inSearch, setInSearch] = useState(null);
    const [searchList, setSearchList] = useState()

    const [webcamList, setWebcamList] = useState();
    const [loading, setLoading] = useState(true);
    const [connectionError, setConnectionError] = useState(false);

    const [isMobile, setIsMobile] = useState(false);
    //const [current, setCurrent] = useState(true);

    const [webcamFiltered, setWebcamFiltered] = useState([]);
    const [webcamFilter, setWebcamFilter] = useState(false);
    const [provinceActive, setProvinceActive] = useState(null);

    const _searchEvent = () => {
        let filtered = webcamList.filter((item) => item.title.toUpperCase().includes(search.toUpperCase()));

        setSearchList(filtered);
        setInSearch(true);
    }

    const filterWebcamBy = (idx) => {
        if (provinceActive === provinceSlug[idx]) {
            setWebcamFilter(p => !p);
            return
        }

        let filtered = webcamList.filter(item => item.province === provinceSlug[idx])
        setWebcamFiltered(filtered);
        setWebcamFilter(true);
        setProvinceActive(provinceSlug[idx]);
        //console.log(filtered);
    }

    const filterButtonStyle = (e, idx) => {

        e.target.classList.toggle("btn--outline")
        e.target.classList.toggle("btn--primary")

        provinceSlug.forEach(slug => {
            if (slug !== provinceSlug[idx]) {
                document.getElementById("webcam-" + slug).classList.remove("btn--outline")
                document.getElementById("webcam-" + slug).classList.add("btn--primary")
            }
        });
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Webcam" });

        if (window.innerWidth < 960) setIsMobile(true);
        window.addEventListener('resize', handleResize);

        Requests.get_webcams()
        .then(webcam =>{
            //let data =JSON.parse(Crypto.decode(res.data))
            setWebcamList(webcam);
            setLoading(false);
        })
        .catch(err => {
            if (!connectionError) setConnectionError(prev => !prev);
        });            

        return () => (
            window.removeEventListener('resize', handleResize)
        );
    }, [])

    const handleResize = () => {
        if (window.innerWidth < 960) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    return loading ?
        <div style={{ display: 'flex', minHeight: '100vh', paddingTop: 120, flexDirection: "row", justifyContent: 'center', alignItems: (connectionError ? "" : "center") }}>
            {connectionError ?
                <ErrorBanner text={"Riprova più tardi..."} />
                :

                <CircularProgress size={48} />
            }
        </div>

        :

        (
            <div className="webcam-wrapper">
                <div className="search-box">
                    <TextField
                        id="outlined-secondary"
                        label="Nome località"
                        variant="outlined"
                        color="primary"
                        size="small"
                        className="search-box-input"
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" ? document.getElementById("webcam-search-btn").click() : null}
                        value={search ? search : ""}
                        fullWidth
                    />
                    <div className="search-button">
                        <Button id={"webcam-search-btn"} buttonStyle={"btn--primary"} noRounded noLink disableHover={isMobile} onClick={_searchEvent}>CERCA</Button>
                    </div>

                    {
                        search &&
                        <div>
                            <Button buttonStyle={"btn--outline"} noRounded onClick={() => {
                                setInSearch(false);
                                setSearch("");
                            }} noLink>
                                <i className="fas fa-undo"></i>
                            </Button>
                        </div>
                    }
                </div>

                <div className="province-list-filters">
                    <ul>
                        <li id="pf-0">
                            <Button id={"webcam-cs"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded

                                onClick={(e) => {
                                    if (!isMobile) {
                                        filterWebcamBy(0)
                                        filterButtonStyle(e, 0);
                                    }
                                }}

                                onTouchStart={e => {
                                    filterWebcamBy(0)
                                    filterButtonStyle(e, 0);
                                }}

                                fullWidth={isMobile}
                                noLink
                                disableHover
                            >
                                COSENZA
                            </Button>
                        </li>
                        <li id="pf-1">
                            <Button id={"webcam-kr"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded
                                onClick={(e) => {
                                    if (!isMobile) {
                                        filterWebcamBy(1)
                                        filterButtonStyle(e, 1);
                                    }
                                }}

                                onTouchStart={e => {
                                    filterWebcamBy(1)
                                    filterButtonStyle(e, 1);
                                }}

                                fullWidth={isMobile}
                                noLink
                                disableHover
                            >
                                CROTONE
                            </Button>
                        </li>
                        <li id="pf-2">
                            <Button id={"webcam-cz"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded
                                onClick={(e) => {
                                    if (!isMobile) {
                                        filterWebcamBy(2)
                                        filterButtonStyle(e, 2);
                                    }
                                }}

                                onTouchStart={e => {
                                    filterWebcamBy(2)
                                    filterButtonStyle(e, 2);
                                }}

                                fullWidth={isMobile}
                                noLink
                                disableHover
                            >
                                CATANZARO
                            </Button>
                        </li>
                        <li id="pf-3">
                            <Button id={"webcam-vv"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded
                                onClick={(e) => {
                                    if (!isMobile) {
                                        filterWebcamBy(3)
                                        filterButtonStyle(e, 3);
                                    }
                                }}

                                onTouchStart={e => {
                                    filterWebcamBy(3)
                                    filterButtonStyle(e, 3);
                                }}

                                fullWidth={isMobile}
                                noLink
                                disableHover
                            >
                                VIBO VALENTIA
                            </Button>
                        </li>
                        <li id="pf-4">
                            <Button id={"webcam-rc"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded
                                onClick={(e) => {
                                    if (!isMobile) {
                                        filterWebcamBy(4)
                                        filterButtonStyle(e, 4);
                                    }
                                }}

                                onTouchStart={e => {
                                    filterWebcamBy(4)
                                    filterButtonStyle(e, 4);
                                }}

                                fullWidth={isMobile}
                                noLink
                                disableHover
                            >
                                REGGIO CALABRIA
                            </Button>
                        </li>
                    </ul>
                </div>



                {searchList && inSearch && search &&
                    <div className="search webcam">
                        <h3 className="webcam-search-title">Risultati ricerca: </h3>
                        <ul className="webcam-list">
                            {
                                searchList.map((el) => (
                                    //<StationsItem key={item.key} city={item.city} url={item.url} type={item.type} id={item.id} locality={item.locality}/> 
                                    <WebcamItem webcam={el} key={el._id} />
                                ))
                            }
                        </ul>
                    </div>
                }

                <h1>Webcam</h1>

                {!webcamFilter &&
                    <div className="webcam">
                        <ul className="webcam-list">
                            {
                                webcamList.map((el, idx) => <WebcamItem webcam={el} key={el._id} />)
                            }
                        </ul>
                    </div>
                }

                {webcamFilter &&
                    <div className="webcam">
                        <ul className="webcam-list">
                            {
                                webcamFiltered.map((el, idx) => <WebcamItem webcam={el} key={el._id} />)
                            }
                        </ul>
                    </div>
                }

                {
                    webcamFilter && webcamFiltered.length === 0 && <h3 style={{textAlign: "center"}}>Nessuna webcam trovata!</h3>
                }
            </div>
        )
}

function WebcamItem({ webcam }) {
    return (
        <a className="webcam-list-item" href={"/nowcasting/webcam/" + webcam._id}>
            <li>
                <span className="webcam-list-item-info">
                    <i className="fa fa-solid fa-video"></i>
                    <p>{webcam.title}</p>
                </span>
                <i className={true ? "fas fa-chevron-right" : "fas fa-chevron-down"} />
            </li>
        </a>
    )
}


export default Webcam
