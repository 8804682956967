import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import Requests from '../scripts/Requests';

import "./MonthlyArchiveTable.css"
import { Button } from './Button';
import { Box, Paper, TablePagination } from '@material-ui/core';

const editTimestamp = (timestamp) => {
    timestamp = timestamp.replace("T", " ")
    timestamp = timestamp.replaceAll("-", "/")
    return timestamp
}

const monthlyColumns = [
    {
        id: 'temp_min',
        label: 'T°C Min',
        minWidth: 100,
        align: 'left',
        format: (value) => console.log(value),
    },
    {
        id: 'temp_avg_min',
        label: 'T°C Media Min',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toFixed(1),
    },
    {
        id: 'temp_max',
        label: 'T°C Max',
        minWidth: 100,
        align: 'left',
        format: (value) => console.log(value),
    },
    {
        id: 'temp_avg_max',
        label: 'T°C Media Max',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toFixed(1),
    },
    {
        id: 'temp_avg',
        label: 'T°C Media',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toFixed(1),
    },
    {
        id: 'monthly_rain',
        label: 'Pioggia (mm)',
        minWidth: 100,
        align: 'left',
        format: (value) => console.log(value),
    },
    {
        id: 'windspeed_avg',
        label: 'V Medio (km/h)',
        minWidth: 100,
        align: 'left',
        format: (value) => value.toFixed(1),
    },
    {
        id: 'windgust_max',
        label: 'R Max (km/h)',
        minWidth: 100,
        align: 'left',
        format: (value) => console.log(value),
    },
]

const columns = [
    {
        id: 'day',
        label: 'Giorno',
        minWidth: 100,
        align: 'left',
        format: (value) => console.log(value),
    },
    {
        id: 'temp_avg',
        label: 'T°C Med',
        minWidth: 50,
        align: 'right',
        format: (value) => console.log(value),
    },
    {
        id: 'temp_min',
        label: 'T°C Min',
        minWidth: 50,
        align: 'right',
        format: (value) => console.log(value),
    },
    {
        id: 'temp_max',
        label: 'T°C Max',
        minWidth: 50,
        align: 'right',
        format: (value) => console.log(value),
    },
    {
        id: 'dewpoint_min',
        label: 'Dp°C Min',
        minWidth: 50,
        align: 'right',
        format: (value) => value.toFixed(1),
    },
    {
        id: 'dewpoint_max',
        label: 'Dp°C Max',
        minWidth: 50,
        align: 'right',
        format: (value) => value.toFixed(1),
    },
    {
        id: 'humidity_min',
        label: 'UR. % Min',
        minWidth: 50,
        align: 'right'
    },
    {
        id: 'humidity_max',
        label: 'UR. % Max',
        minWidth: 50,
        align: 'right'
    },
    {
        id: 'windspeed_avg',
        label: 'V Med (km/h)',
        minWidth: 50,
        align: 'right'
    },
    {
        id: 'windgust_max',
        label: 'R Max (km/h)',
        minWidth: 50,
        align: 'right'
    },
    {
        id: 'pressure_min',
        label: 'Pres. Min (hPa)',
        minWidth: 50,
        align: 'right'
    },
    {
        id: 'pressure_max',
        label: 'Pres. Max (hPa)',
        minWidth: 50,
        align: 'right'
    },
    {
        id: 'daily_rain',
        label: 'Pioggia (mm)',
        minWidth: 50,
        align: 'right'
    }
];

const MonthlyArchiveTable = ({ stationId }) => {
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [width, setWidth] = useState(window.innerWidth);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width < 960;

    const [tableData, setTableData] = useState([]);
    const [monthStats, setMonthStats] = useState(null);
    const [columnsName, setColumnsName] = useState([]);

    // Funzione per generare la tabella con i dati selezionati
    const generateTableData = () => {
        Requests.get_station_history_monthly(stationId, month, year)
            .then(res => {
                setTableData(res)
            })
            .catch(err => {
                console.log(err)
                setTableData([])
            })

        Requests.get_station_history_monthly_stats(stationId, month, year)
            .then(res => {
                setMonthStats(res)
            })
            .catch(err => {
                console.log(err)
                setMonthStats(null)
            })
    };

    return (
        <div className='archive-container'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className='archive-title'>
                        <h3>Archivio storico</h3>
                        <p>Seleziona la data dai menu a tendina</p>
                    </div>
                </Grid>
                
                <Grid item xs={isMobile ? 4 : 3}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Mese</InputLabel>
                        <Select
                            value={month}
                            label="Mese"
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            {/* Esempio di opzioni per i mesi */}
                            {isMobile ?
                                [...Array(13).keys()].map((month, index) => {
                                    if (month !== 0) {
                                        return (
                                            <MenuItem key={index} value={month}>
                                                {month}
                                            </MenuItem>
                                        )
                                    }
                                })
                                :
                                [
                                    'Gennaio',
                                    'Febbraio',
                                    'Marzo',
                                    'Aprile',
                                    'Maggio',
                                    'Giugno',
                                    'Luglio',
                                    'Agosto',
                                    'Settembre',
                                    'Ottobre',
                                    'Novembre',
                                    'Dicembre',
                                ].map((month, index) => (
                                    <MenuItem key={index} value={index + 1}>
                                        {month}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={isMobile ? 4 : 3}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Anno</InputLabel>
                        <Select
                            value={year}
                            label="Anno"
                            onChange={(e) => setYear(e.target.value)}
                        >
                            {/* Esempio di opzioni per gli anni */}
                            {Array.from({ length: 2 }, (_, i) => new Date().getFullYear() - i).map(
                                (year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={isMobile ? 12 : 3} >
                    <Box display="flex" justifyContent="center" alignItems="center" height={"100%"}>
                        <Button buttonStyle="btn--primary" buttonSize={"btn--medium"} onClick={generateTableData}>
                            VISUALIZZA
                        </Button>
                    </Box>

                </Grid>
                {monthStats &&
                
                    <Grid item xs={12}>
                        <h3 className='month-stats-title'>RIEPILOGO MENSILE</h3>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table aria-label="table">
                                    <TableHead style={{backgroundColor: "var(--primary)"}}>
                                        <TableRow>
                                            {monthlyColumns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                    className='table-cell-head'
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {
                                    <TableBody>
                                        <TableRow hover role="checkbox" tabIndex={-1} key={1}>
                                            {monthlyColumns.map((column) => {
                                                const value = monthStats[column.id];
                                                return (
                                                    <TableCell 
                                                        key={column.id} 
                                                        align={column.align}
                                                        id={column.id + "_month"}
                                                        className='table-cell'
                                                        >

                                                        {
                                                            value ?
                                                                (value === null) ?
                                                                    "-"
                                                                    :
                                                                    value.toFixed(1)
                                                                :
                                                                "-"
                                                        }
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableBody>
                                        }
                                </Table>
                            </TableContainer>
                        </Paper>
                    
                </Grid>
                }

                { /*
                    <Grid item xs={12}>
                        <div className='month-stats'>
                            <h3>DATI MENSILI {month}/{year}</h3>
                            <ul>
                                <li className='temp-min'>Temp. Min: {monthStats['temp_min']}°c</li>
                                <li className='temp-max'>Temp. Max: {monthStats['temp_max']}°c</li>
                                <li className='temp-avg'>Temp. Media: {monthStats['temp_avg'].toFixed(1)}°c</li>
                                <li>Precipitazioni mensili: {monthStats['monthly_rain']} mm</li>
                                <li>Raffica vento massima: {monthStats['windspeed_max']} km/h</li>
                            </ul>
                        </div>
                    </Grid>
                */}
                <Grid item xs={12}>
                    <h3 className='month-stats-title'>RIEPILOGO GIORNALIERO</h3>
                    {tableData.length == 0 ?
                        <div className='no-data'>
                            <h4>Nessun dato trovato</h4>
                        </div>
                        :

                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table aria-label="table" stickyHeader>
                                    <TableHead className='table-head' >
                                        <TableRow >
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, backgroundColor: "var(--primary)" }}
                                                    className='table-cell-head'
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {tableData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                return (
                                                    <TableRow 
                                                        hover 
                                                        role="checkbox" 
                                                        tabIndex={-1} 
                                                        key={row.timestamp}
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell 
                                                                    className={'table-cell'}
                                                                    key={column.id} 
                                                                    align={column.align}
                                                                    id={column.id}
                                                                >

                                                                    {
                                                                        value ?
                                                                            (column.id === "timestamp") ?
                                                                                editTimestamp(value)
                                                                                :
                                                                                (
                                                                                    column.id !== "day" && 
                                                                                    column.id !== "humidity_min" &&
                                                                                    column.id !== "humidity_max"
                                                                                ) ?
                                                                                value.toFixed(1)
                                                                                : 
                                                                                value
                                                                            :
                                                                            "-"
                                                                    }
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={tableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    }
                </Grid>
            </Grid>
        </div>
    );
};

export default MonthlyArchiveTable;
