import React, { useEffect } from 'react'
import Parallax from '../components/Parallax'
import Stations from '../components/Stations'

import "./Nowcasting.css"
import ReactGA from 'react-ga4';
import Stazioni from '../components/Stazioni';

function Nowcasting() {
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Nowcasting" });
        return null;
    },[]);

    return (
        <div className="nowcasting-container">
            <Parallax img={"../img/slide2.jpg"} position="bottom"/>
            {/*<Stations />*/}
            <Stazioni />
        </div>
    )
}

export default Nowcasting
