import React, { useState, useEffect } from 'react'
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';
//import 'chartjs-adapter-moment';
import axios from 'axios';
import { Button } from '../components/Button';
import { Crypto } from '../scripts/crypto';

import "./StationChart.css";
import Requests from '../scripts/Requests';
import { convertTimestampToDate, getTimeInHHMM } from '../scripts/chartjs_utils'

function StationChart({ stationId, }) {

    const [chart, setChart] = useState();
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width < 960;

    async function getData() {
        let data = await Requests.get_station_daily(stationId).catch(err => console.log(err))

        if (!data) {
            return false
        }

        let time = [];
        const temp = [];
        const dewpoint = []
        const windSpeed = [];
        const humidity = [];
        const pressure = [];
        const precip = [];

        for (var i = 0; i < data.length; i++) {
            let row = data[i]
            let timestamp = row['timestamp']
            let date = convertTimestampToDate(timestamp)

            time.push(getTimeInHHMM(date))
            temp.push(row['temp'])

            if (row['dewpoint']) {
                dewpoint.push(row['dewpoint'].toFixed(1))
            }

            windSpeed.push(row['windSpeed'])
            humidity.push(row['humidity'])
            if (row['pressure']) {
                pressure.push(row['pressure'].toFixed(1))
            }

            if (row['precipTotal']) {
                precip.push(row['precipTotal'].toFixed(1))
            }
        }
        createChart(time, [temp, dewpoint, windSpeed, humidity, pressure, precip], time.length > 60 ? "hour" : "minute", time.length > 60 ? 1 : 5);
    }

    function createChart(labels, values, stepType = "hour", stepSize = "1") {
        var ctx = document.getElementById('chart');
        //console.log(labels, values);
        var defaultLabels = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];
        var data = {
            labels: labels,
            connectNullData: true,
            datasets: [
                {
                    label: isMobile ? "Temp." : 'Temperatura',
                    yAxisID: "y",
                    data: values[0],
                    backgroundColor: "#AA0000",
                    borderColor: "#AA0000",
                    borderWidth: 1,
                    tension: 0,
                    stepped: false,
                    cubicInterpolationMode: "monotone",

                },
                {
                    label: isMobile ? "Dewpoint" : 'Dewpoint',
                    yAxisID: 'y_dewpt',
                    data: values[1],
                    borderWidth: 1,
                    backgroundColor: "#A6006F",
                    borderColor: "#A6006F",
                    tension: 0,
                    stepped: false,
                    cubicInterpolationMode: "monotone",
                    hidden: true
                },
                {
                    label: 'Vento',
                    yAxisID: 'y_wind',
                    data: values[2],
                    backgroundColor: '#FF8400',
                    borderColor: '#FF8400',
                    borderWidth: 1,
                    tension: 0.1,
                    hidden: true
                },
                {
                    label: isMobile ? "Umi." : 'Umidità',
                    yAxisID: 'y_hum',
                    data: values[3],
                    backgroundColor: '#1414AA',
                    borderColor: '#1414AA',
                    borderWidth: 1,
                    tension: 0.1,
                    hidden: false
                },
                {
                    label: isMobile ? "Press." : 'Pressione',
                    yAxisID: 'y_press',
                    data: values[4],
                    backgroundColor: '#83CB17',
                    borderColor: '#83CB17',
                    borderWidth: 1,
                    tension: 0.1,
                    hidden: false
                },
                /*
                {
                    label: isMobile ? "Precip." : 'Precipitazioni',
                    yAxisID: 'y_rain',
                    data: values[5],
                    backgroundColor: '#00BBFF',
                    borderColor: '#00BBFF',
                    borderWidth: 1,
                    tension: 0.1,
                    hidden: true
                }
                */
            ]
        }

        var myChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: {
                spanGaps: false,
                borderColor: "#ff0000",
                backgroundColor: "#ff4d4d",
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 5,
                        hoverRadius: 5
                    }
                },
                scales: {

                    x:
                    {
                        type: "time",
                        time: {
                            parser: "HH:mm",
                            unit: stepType,
                            displayFormats: {
                                "hour": "HH:mm",
                                "minute": "HH:mm"
                            },
                            tooltipFormat: "HH:mm",
                            stepSize: stepSize,
                            minUnit: "00:00",
                            maxUnit: "23:59"
                        }
                    },
                    //yAxis: [],                    
                    y: {
                        display: true,
                        beginAtZero: false,
                        type: 'linear',
                        position: 'left',
                        grid: {},
                        ticks: {
                            color: "#AA0000",
                            stepSize: 0.5,
                            callback: function (tick, index) {
                                return parseFloat(tick).toFixed(1);
                            }
                        }
                    },

                    y_hum: {
                        position: "left",
                        display: true,
                        ticks: {
                            color: "#1414AA",
                            callback: function (tick, index) {
                                return parseFloat(tick).toFixed(1);
                            }
                        }
                    },
                    y_press: {
                        position: "right",
                        display: true,
                        ticks: {
                            color: "#83CB17",
                            callback: function (tick, index) {
                                return parseFloat(tick).toFixed(1);
                            }
                        }
                    },
                    y_dewpt: {
                        display: false,
                        ticks: {
                            color: "#A6006F"
                        }
                    },
                    y_wind: {
                        display: false,
                        position: "right",
                        ticks: {
                            color: "#FF8400"
                        },
                        title: "wind"
                    },
                    /*
                    y_rain: {
                        display: false,
                        ticks: {
                            color: "#00BBFF",
                            callback: function (tick, index) {
                                return parseFloat(tick).toFixed(1);
                            }
                        }
                    }
                    */
                }
            }
        });

        myChart.legend.options.onClick = (evt, legendItem, legend)=>{
            var index = legendItem.datasetIndex;
            var scaleID = data.datasets[index].yAxisID;
            var scale = myChart.scales[scaleID];

             // Modifica la legenda o il dataset in base alle tue esigenze
            // Ad esempio, puoi nascondere o mostrare un dataset
            myChart.data.datasets[index].hidden = !myChart.data.datasets[index].hidden;

            scale.options.display = !scale.options.display;
            //legendItem.hidden = !legendItem.hidden
            

            myChart.update();
        }
        setChart(myChart);
    }

    useEffect(() => {
        async function init() {
            let res = await getData();
            //createChart();
        }
        init();
        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, []);

    const downloadChart = () => {
        var a = document.createElement("a");
        a.href = chart.toBase64Image();
        a.download = "chart.jpg";
        a.click();
        a.remove();
    }

    return (
        <div className="chart-container">
            <div style={{ display: "block", marginBottom: "24px" }}>
                <canvas id="chart"></canvas>
            </div>
            <Button buttonStyle="btn--primary" buttonSize={isMobile ? "btn--small" : "btn--medium"} onClick={downloadChart}>
                SALVA
            </Button>
        </div>
    )
}

export default StationChart
