import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND
const JSON_FORMAT = "?format=json"

//const CAPTCHA = "/api/recaptcha_verify?code="
const STATS = "/api/stats_live"
const STATIONS = "/api/stations/info"
const STATIONS_LIVE = "/api/stations/live"
const STATION_DAILY = "/api/stations/daily/"
const STATION_HISTORY = "/api/stations/history"
const SAT = "/api/satellites"
const PARTNERS = "/api/partners"
const WEBCAM = "/api/webcam"
const SNOW = "/api/snow"
const REPORTS = "/api/reports"

const MAIL = "/api/email"
const ADHESIONS = "/api/adhesion"
const MAINTENANCE = "/api/maintenance/status"

export default class Requests {

    /*
    static async verify_captcha(code) {
        let result = await axios.get("http://localhost:8000" + CAPTCHA + code)
        if (result.status === 200) {
            return result.data
        }

        return false
    }
    */

    static async get_maintenance() {
        let result = await axios.get(API_URL + MAINTENANCE + JSON_FORMAT)
        if (result.status === 200) {
            return result.data.status
        }

        return false
    }

    static async get_all_active_reports() {
        let result = await axios.get(API_URL + REPORTS + "/getAll" + JSON_FORMAT)
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async get_report_by_id(id) {
        let result = await axios.get(API_URL + REPORTS + "/get?reportID=" + id)
        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async search_comuni(query = ""){
        let result = await axios.get(API_URL + REPORTS + "/searchComuni?query="+ query )
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async get_city_by_coords(lat, lng){
        let result = await axios.get(API_URL + REPORTS + "/getByCoords?lat="+ lat  + "&lng=" + lng)
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async localizate_comune(comune){
        let result = await axios.get(API_URL + REPORTS + "/localizate?comune="+ comune )
        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async send_new_report(payload) {
        //const blob = base64toBlob(payload['img'], 'image/jpeg');
        
        const blob = await fetch(payload['img'])
        .then(res => res.blob())
        
        const formData = new FormData();
        formData.append("city", payload['city'])
        formData.append("locality", payload['locality'])
        formData.append('author', payload['author'])
        formData.append("lat", parseFloat(payload['lat']))
        formData.append("long", parseFloat(payload['lng']))
        formData.append("img", blob, payload['filename'])
        formData.append("captcha", payload['captcha'])
        let headers = {
            'Content-Type': 'multipart/form-data', 
        }
        //let result = await axios.post(API_URL + REPORTS + "/", formData, {
        //    headers: headers
        //})

        let result = await axios.post(API_URL + REPORTS + "/", formData, {
            headers: headers
        })
        if (result.status === 201) {
            return result.data
        }

        return []
    }

    static async get_station_live_by_id(station_id) {
        let result = await axios.get(API_URL + STATIONS_LIVE + "/" + station_id + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return []
    }

    static async get_stations_live() {
        let result = await axios.get(API_URL + STATIONS_LIVE + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return []
    }

    static async get_station_info_by_id(station_id) {
        let result = await axios.get(API_URL + STATIONS + "/" + station_id + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return []
    }

    static async get_stations_info() {
        let result = await axios.get(API_URL + STATIONS + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return []
    }

    static async get_station_daily(station_id) {
        let result = await axios.get(API_URL + STATION_DAILY + station_id + JSON_FORMAT)
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async get_station_history(stationId, day, month, year) {
        var payload = {
            "stationId": stationId,
            "day": day,
            "month": month,
            "year": year
        }
        let result = await axios.post(API_URL + STATION_HISTORY + JSON_FORMAT, payload)
        if (result.status === 200) {
            return result.data
        }
        return []
    }

    static async get_station_history_monthly(stationId, month, year) {
        var payload = {
            "stationId": stationId,
            "month": month,
            "year": year
        }
        //let result = await axios.post(API_URL + STATION_HISTORY + "/monthly" + JSON_FORMAT, payload)
        let result = await axios.post(API_URL + STATION_HISTORY + "/monthly" + JSON_FORMAT, payload)
        if (result.status === 200) {
            return result.data
        }
        return []
    }

    static async get_station_history_monthly_stats(stationId, month, year) {
        var payload = {
            "stationId": stationId,
            "month": month,
            "year": year
        }
        //let result = await axios.post(API_URL + STATION_HISTORY + "/monthly_stats" + JSON_FORMAT, payload)
        let result = await axios.post(API_URL + STATION_HISTORY + "/monthly_stats" + JSON_FORMAT, payload)
        if (result.status === 200) {
            return result.data
        }
        return []
    }

    static async get_webcams() {
        let result = await axios.get(API_URL + WEBCAM + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return []
    }

    static async get_webcam_by_id(webcam_id) {
        let result = await axios.get(API_URL + WEBCAM + "/" + webcam_id + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return {}
    }

    static async get_snow() {
        let result = await axios.get(API_URL + SNOW + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return {}
    }

    static async get_snow_by_id(snow_id) {
        let result = await axios.get(API_URL + SNOW + "/" + snow_id + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return {}
    }

    static async get_snow_history_by_id(snow_id) {
        let result = await axios.get(API_URL + SNOW + "/year/" + snow_id + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return {}
    }

    static async get_live_stats() {
        let result = await axios.get(API_URL + STATS + JSON_FORMAT)
        if (result.status === 201) {
            return result.data
        }

        return {}
    }

    static async get_satellites() {
        let path = API_URL + SAT + JSON_FORMAT
        let result = await axios.get(path)
        if (result.status === 201 || result.status === 200) {
            return result.data
        }

        return {}
    }

    static async get_partners() {
        let path = API_URL + PARTNERS + JSON_FORMAT
        let result = await axios.get(path)
        if (result.status === 201 || result.status === 200) {
            return result.data
        }

        return {}
    }

    static async add_email(data) {
        let path = API_URL + MAIL + JSON_FORMAT
        let result = await axios.post(path, data)
        if (result.status === 201 || result.status === 200) {
            return true
        }

        return false
    }

    static async add_adhesion(data) {
        let payload = {
            "name": data['name'],
            "surname": data['surname'],
            "email": data['email'],
            "data": data
        }

        if ("cell" in data) {
            payload['cell'] = data['cell']
        }

        let path = API_URL + ADHESIONS + JSON_FORMAT
        let result = await axios.post(path, payload)
        if (result.status === 201 || result.status === 200) {
            return true
        }

        return false
    }


}