import React from 'react'

import './Parallax.css';

function Parallax({img, position}) {
    const imgSrc = img ? img : "";
    const pos = position ? position : "";

    return (
        <div style={{backgroundImage: "url("+imgSrc+")", backgroundPosition: pos}} className="parallax-container">
        </div>
    )
}

export default Parallax
