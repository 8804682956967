import React from 'react'
import ErrorBanner from '../components/ErrorBanner';

import "./NotFound.css";

function NotFound() {
  return (
    <div className='container-404'>
        <ErrorBanner text={"Pagina non trovata"} />
    </div>
  )
}

export default NotFound