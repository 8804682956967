import React, { useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import radarSVG from '../img/radar.svg'
import webcamSVG from '../img/webcam.svg'
import mappaSVG from '../img/mappa.svg'
import satellitiSVG from '../img/satelliti.svg'


import './Home.css'
import ReactGA from 'react-ga4'
import axios from 'axios'
import $ from 'jquery'
import RSSFeed from '../components/RSSFeed'
import Slider from '../components/Slider'
import Requests from '../scripts/Requests'

function Home() {

    const [estremi, setEstremi] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width < 960;

    const webcamLink = "/nowcasting/webcam";
    const mappaLink = "/mappa";
    const radarLink = "/nowcasting/radar";
    const satellitiLink = "/nowcasting/satelliti";

    const ArrowRight = ({ color, width, height, className, style }) => {
        return (
            <svg viewBox='0 0 25 100' width={width} height={height} className={className} style={style}>
                <polyline
                    id="st0"
                    points="0.8,97.3 24.4,50 0.8,2.7 "
                    style={{ fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, stroke: color }}
                />
            </svg>
        )
    }

    const ArrowDown = ({ color, width, height, className, style }) => {
        return (
            <svg viewBox='-100 0 100 25' width={width} height={height} className={className} style={style}>
                <polyline
                    id="st0"
                    points="0.8,97.3 24.4,50 0.8,2.7 "
                    style={{ fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, stroke: color, transform: "rotate(90deg)" }}
                />
            </svg>
        )
    }

    /*
    async function getEstremi() {
        let temp = await axios.get(process.env.REACT_APP_BACKEND + "/calcLive/temp");
        let tempMin = await axios.get(process.env.REACT_APP_BACKEND + "/calcLive/tempLow");
        let tempMax = await axios.get(process.env.REACT_APP_BACKEND + "/calcLive/tempHigh");
        let tempAverage = await axios.get(process.env.REACT_APP_BACKEND + "/calcAverage/temp");
        let rain = await axios.get(process.env.REACT_APP_BACKEND + "/calcLive/precipTotal");
        let wind = await axios.get(process.env.REACT_APP_BACKEND + "/calcLive/windSpeedHigh");

        return { temp: temp.data, tempMin: tempMin.data, tempMax: tempMax.data, tempAverage: tempAverage.data, rain: rain.data, wind: wind.data };
    }
    */

    function handleWindowSizeChange() {
        let v = window.innerWidth;
        setWidth(v);
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Home" });

        $(".home-section-banner").hover(() => {
            $(".blog-arrow-icon").toggleClass("pull-up-arrow");
        })
       
        
        Requests.get_live_stats()
        .then(res => {
            setEstremi(res)
        })
        .catch(e => console.log(e));
       

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function arrowPosition() {
        if (window.innerWidth < 1300) {
            return -150;
        } else if (window.innerWidth >= 1300) {
            return -100;
        } else {
            return 0;
        }
    }

    return estremi ?
        (
            <>
                {/*<CarouselContainer data={estremi} isMobile={isMobile} />*/}
                <Slider data={estremi} isMobile={isMobile}>
                    {!isMobile && window.innerWidth >= 1280 &&
                        <div className='arrow-row'>
                            <ArrowDown
                                color={"var(--primary)"}
                                height={50}
                                width={200}
                                className="slide-bottom"
                            />
                        </div>
                    }
                </Slider>

                <div className='home-container'>
                    {/*isMobile &&
                        <div className='home-section'>
                            <RSSFeed isMobile={isMobile} />
                        </div>
                    */}

                    <div className={!isMobile ? 'home-row' : ""}>
                        {isMobile && <hr />}

                        <section className='home-section'>
                            <a href={mappaLink}>
                                {/*<div className='section-img' style={{ backgroundImage: 'url("./img/mappa.png")' }}></div>*/}
                                <img className='section-img' src={mappaSVG} width="256px" height={"256px"} />
                            </a>
                            <div className='section-info'>
                                <h3 className='section-title'>MAPPA</h3>
                                <p className='section-desc'>
                                    In tempo reale, con diverse esclusive, per monitorare in dettaglio i dati meteo sulla nostra regione.
                                </p>
                                <a className='section-link' href={mappaLink}>SCOPRI {'>'}</a>
                            </div>
                        </section>

                        {isMobile && <hr />}

                        <section className={'home-section'}>
                            <a href={webcamLink}>
                                {/*<div className='section-img' style={{ backgroundImage: 'url("./img/webcam.png")' }}></div>*/}
                                <img className='section-img' src={webcamSVG} width="256px" height={"256px"} />
                            </a>
                            <div className='section-info'>
                                <h3 className='section-title'>WEBCAM</h3>
                                <p className='section-desc'>
                                    Immagini auto aggiornanti per vivere ogni momento e ogni condizione meteo in prima persona.
                                </p>
                                <a className='section-link' href={webcamLink}>SCOPRI {'>'}</a>
                            </div>
                        </section>

                    </div>

                    <div className={!isMobile ? 'home-row' : ""}>
                        {isMobile && <hr />}
                        <section className='home-section'>
                            <a href={radarLink}>
                                {/*<div className='section-img' style={{ backgroundImage: 'url("./img/radar.png")' }}></div>*/}
                                <img className='section-img' src={radarSVG} width="256px" height={"256px"} />
                            </a>
                            <div className='section-info'>
                                <h3 className='section-title'>RADAR</h3>
                                <p className='section-desc'>
                                    Per scoprire in tempo reale la distribuzione e l’intensità di piogge e temporali.
                                </p>
                                <a className='section-link' href={radarLink}>SCOPRI {'>'}</a>
                            </div>
                        </section>

                        {isMobile && <hr />}

                        <section className={'home-section'}>
                            <a href={satellitiLink}>
                                {/*<div className='section-img' style={{ backgroundImage: 'url("./img/satelliti.png")' }}></div>*/}
                                <img className='section-img' src={satellitiSVG} width="256px" height={"256px"} />
                            </a>
                            <div className='section-info'>
                                <h3 className='section-title'>SATELLITI</h3>
                                <p className='section-desc'>
                                    Gli strumenti più efficaci per le condizioni in atto, anche su larga scala, e per ipotizzare quelle delle ore immediatamente successive.
                                </p>
                                <a className='section-link' href={satellitiLink}>SCOPRI {'>'}</a>
                            </div>

                        </section>

                    </div>

                    <section className='home-section-banner'>
                        <div className='blog-banner-background' ></div>
                        <a
                            className='blog-banner'
                            href='http://blog.calabriaweatherdata.it'
                            style={{ backgroundImage: 'url("/img/logo_blog.png")' }}
                        >
                        </a>
                        <div className='blog-arrow-icon pull-up-arrow'>
                            <ArrowRight width={50} height={200} color="var(--primary)"></ArrowRight>
                        </div>
                    </section>
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText="Accetta"
                    cookieName="cookieAccept"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ backgroundColor: "#f7941e", color: "#fff", fontSize: "13px" }}
                    expires={150}
                >
                    Questo sito web utilizza i cookies per ottimizzare l'esperienza dell'utente{" "}

                </CookieConsent>
            </>
        )
        :
        (
            <>
                <Slider data={estremi} isMobile={isMobile} />
                {/*<CarouselContainer data={null} />*/}
                {!isMobile && window.innerWidth >= 1280 &&
                    <div className='arrow-row'>
                        <ArrowDown
                            color={"var(--primary)"}
                            height={50}
                            width={200}
                            className="slide-bottom"
                            style={{ top: arrowPosition() }}
                        />
                    </div>
                }
                <div className='home-container'>
                    {//SEZIONE ARTICOLI
                        /*isMobile &&
                            <div className='home-section'>
                                <RSSFeed isMobile={isMobile} />
                            </div>
                        */
                    }

                    <div className={!isMobile ? 'home-row' : ""}>
                        {isMobile && <hr />}

                        <section className='home-section'>
                            <a href={mappaLink}>
                                <div className='section-img' style={{ backgroundImage: 'url("./img/mappa.png")' }}></div>
                            </a>
                            <div className='section-info'>
                                <h3 className='section-title'>MAPPA</h3>
                                <p className='section-desc'>
                                    In tempo reale, con diverse esclusive, per monitorare in dettaglio i dati meteo sulla nostra regione.
                                </p>
                                <a className='section-link' href={mappaLink}>SCOPRI {'>'}</a>
                            </div>
                        </section>

                        {isMobile && <hr />}

                        <section className={'home-section'}>
                            <a href={webcamLink}>
                                <div className='section-img' style={{ backgroundImage: 'url("./img/webcam.png")' }}></div>
                            </a>
                            <div className='section-info'>
                                <h3 className='section-title'>WEBCAM</h3>
                                <p className='section-desc'>
                                    Immagini auto aggiornanti per vivere ogni momento e ogni condizione meteo in prima persona.
                                </p>
                                <a className='section-link' href={webcamLink}>SCOPRI {'>'}</a>
                            </div>
                        </section>

                    </div>

                    <div className={!isMobile ? 'home-row' : ""}>
                        {isMobile && <hr />}
                        <section className='home-section'>
                            <a href={radarLink}>
                                <div className='section-img' style={{ backgroundImage: 'url("./img/radar.png")' }}></div>
                            </a>
                            <div className='section-info'>
                                <h3 className='section-title'>RADAR</h3>
                                <p className='section-desc'>
                                    Per scoprire in tempo reale la distribuzione e l’intensità di piogge e temporali.
                                </p>
                                <a className='section-link' href={radarLink}>SCOPRI {'>'}</a>
                            </div>
                        </section>

                        {isMobile && <hr />}

                        <section className={'home-section'}>
                            <a href={satellitiLink}>
                                <div className='section-img' style={{ backgroundImage: 'url("./img/satelliti.png")' }}></div>
                            </a>
                            <div className='section-info'>
                                <h3 className='section-title'>SATELLITI</h3>
                                <p className='section-desc'>
                                Gli strumenti più efficaci per le condizioni in atto, anche su larga scala, e per ipotizzare quelle delle ore immediatamente successive.
                                </p>
                                <a className='section-link' href={satellitiLink}>SCOPRI {'>'}</a>
                            </div>

                        </section>

                    </div>

                    <section className='home-section-banner'>
                        <div className='blog-banner-background' ></div>
                        <a
                            className='blog-banner'
                            href='http://blog.calabriaweatherdata.it'
                            style={{ backgroundImage: 'url("/img/logo_blog.png")' }}
                        >
                        </a>
                        <div className='blog-arrow-icon pull-up-arrow'>
                            <ArrowRight width={50} height={200} color="var(--primary)"></ArrowRight>
                        </div>
                    </section>
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText="Accetta"
                    cookieName="cookieAccept"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ backgroundColor: "#f7941e", color: "#fff", fontSize: "13px" }}
                    expires={150}
                >
                    Questo sito web utilizza i cookies per ottimizzare l'esperienza dell'utente{" "}

                </CookieConsent>
            </>
        )

}

export default Home
