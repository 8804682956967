import React, { useState, useEffect } from 'react'
import { Button } from '../components/Button';
import Parallax from '../components/Parallax';
import { TextField, CircularProgress } from '@material-ui/core'

import './Contatti.css'

import ReactGA from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import Requests from '../scripts/Requests';

function Contatti() {
    const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [inError, setInError] = useState(false);
    const [sended, setSended] = useState(false);
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState(false);

    const captchaActive = true;

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Contatti" });
    }, []);

    const onChange = (value) => {
        setCaptcha(value)
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    const _checkForm = () => {
        if (nome === "") return false;
        if (email === "") {
            return false;
        } else {
            if (!validateEmail(email)) return false;
        }
        if (message === "") return false;
        return true;
    }

    const _sendMail = async () => {
        if (_checkForm()) {
            setLoading(true);
            setInError(false);
            let sended = false;
            let errorMessage = "";
            let data = {
                name: nome,
                email: email,
                content: message,
                captcha: captcha
            }
            
            /*
            await axios.post(process.env.REACT_APP_BACKEND + "/mail", data)
                .then(res => {
                    if (res.data) {
                        sended = true;
                        setSended(true);
                    }
                    else errorMessage = res.data.error;
                })
                .catch(err => {
                    setInError(true)
                    errorMessage = err.message;
                });
            */

            Requests.add_email(data)
            .then(res =>{
                if (res) {
                    sended = true;
                    setSended(true);
                }
            })
            .catch(err => {
                setInError(true)
                errorMessage = err.message;
            })

            if (sended) {
                console.log("Email inviata");
            } else {
                console.error("Errore invio email");
                console.error(errorMessage);
            }
        } else {
            setInError(true);
            console.error("Form invalid!");
        }
    }

    useEffect(()=>{
        console.log(RECAPTCHA_KEY);
    },)

    return !loading ? (

        <div className="contatti-container">
            <Parallax img="../img/capo_vaticano.jpg" position="bottom" />
            <div className="contatti-title">
                <h1>CONTATTI</h1>
            </div>
            <form>
                <label className="contatti-label">
                    Nome:
                    <TextField
                        id="standard-basic"
                        placeholder="Nome"
                        variant="outlined"
                        type="text"
                        size="small"
                        fullWidth
                        value={nome}
                        error={inError && nome === ""}
                        onChange={(e) => setNome(e.target.value)}
                    />
                </label>
                <label className="contatti-label">
                    Email:
                    <TextField
                        id="standard-basic"
                        placeholder="Email"
                        variant="outlined"
                        type="email"
                        size="small"
                        fullWidth
                        value={email}
                        error={inError && !validateEmail(email)}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </label>
                <label className="contatti-textarea">
                    Messaggio:
                    <TextField
                        id="standard-basic"
                        placeholder="Inserisci il tuo messaggio..."
                        variant="outlined"
                        size="small"
                        multiline
                        rows={4}
                        value={message}
                        error={inError && message === ""}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </label>

                {captchaActive &&
                    <div className="recaptcha-contatti">
                        <ReCAPTCHA
                            sitekey={RECAPTCHA_KEY}
                            onChange={onChange}
                            onExpired={()=>setCaptcha(false)}
                        />
                    </div>
                }


                {
                    captchaActive ?
                        <div className="contatti-button">
                            {captcha &&
                                <Button buttonStyle="btn--primary" onClick={() => {
                                    _sendMail();
                                }}>
                                    INVIA
                                </Button>
                            }
                        </div>
                        :
                        <div className="contatti-button">
                            <Button buttonStyle="btn--primary" onClick={() => {
                                _sendMail();
                            }}>
                                INVIA
                            </Button>
                        </div>
                }



            </form>

        </div>
    ) : (
        <div className="contatti-wrapper">

            {
                inError ?
                    <>
                        <h3>Errore nell'invio del messaggio</h3>
                        <p>Risolveremo al più presto, riprova più tardi...</p>
                    </>
                    :
                    <>
                        {sended ?
                            <>
                                <h3>Grazie di averci contattato!</h3>
                                <p>Risponderemo nel più breve tempo possibile...</p>
                            </>
                            :
                            <>
                                <p style={{ paddingBottom: "5px" }}>Attendere, l'invio può richiedere alcuni secondi...</p>
                                <CircularProgress size={48} color="primary" />
                            </>
                        }
                    </>
            }
        </div>
    )
}

export default Contatti
