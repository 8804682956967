import React, { useEffect, useState } from 'react'
import {CircularProgress} from '@material-ui/core' 
import {Link} from 'react-router-dom'

import requestStation from '../scripts/request_station';
import './PopupBox.css';
import { valueInJSON } from '../scripts/functions';

function PopupBox({popupInfo}) {

    const [json, setJson] = useState(popupInfo);

    const [dataLoaded, setDataLoaded] = useState(false);
    const [inError, setInError] = useState(false);

    useEffect(()=>{
        if(popupInfo !== null) {
            setJson(popupInfo);
            setDataLoaded(true);
        }
    },[popupInfo])

    return (
        <div className="popup-container">
            {dataLoaded && !inError &&
            <div className="popup-head">
                <p className="popup-subtitle">{json.locality ? json.locality : null}</p>
                <p className="popup-title">{json.city}</p>
                <p className="popup-update">
                    {json.updateDate ? json.updateDate + " | ": null}{json.updateTime ? json.updateTime : null}
                </p>
            </div>
            }
            { dataLoaded && !inError &&
            <div className="popup-info">
                { valueInJSON("temp", json) &&
                <span className="popup-info-item">
                    <i className="fas fa-temperature-low"></i>
                    <p>{json.temp}°c</p>
                </span>
                }
                { valueInJSON("humidity", json)  &&
                <span className="popup-info-item">
                    <i className="fas fa-tint"></i>
                    <p>{json.humidity}%</p>
                </span>
                }
                { valueInJSON("precipTotal", json)  &&
                <span className="popup-info-item">
                    <i className="fas fa-cloud-rain"></i>
                    <p>{json.precipTotal} mm</p>
                </span>
                }
            </div>
            }

            {!dataLoaded && <div className="progress-wrapper"><CircularProgress size={20}/></div>}
            {inError &&
                <div className={"error-wrapper"}>
                    <i className="fas fa-times" style={{color: "red", fontSize:"18px", padding:"5px"}}></i>
                    Errore caricamento dati.
                </div>
            }
            {dataLoaded && !inError &&
            <div className="popup-link">
                <Link to={"/station/" + popupInfo.id} className="popup-link-text">
                    Vai alla stazione {">"}
                </Link>
            </div>
            }
            
        </div>
    )
}

export default PopupBox
