import React, {useEffect, useState} from 'react'

import ReactGA from 'react-ga4';
import "./Radar.css"

function Radar() {

    const [height, setHeight] = useState(0);

    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Radar" });
            
        //setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        window.addEventListener('resize', _handleResize);
        return()=> {
            window.removeEventListener('resize', _handleResize);
        }

    },[]);

    const _handleResize = ()=>{
        setHeight(window.innerHeight);
        //setWidth(window.innerWidth);
    }

    return (
        <div className="radar-wrapper">
            <div className="radar-header">
                <h1>RADAR METEO</h1>
            </div>
            {

            /*
            <div className="radar-protezione-civile">
                <h5>(Fonte radar: <a href="http://www.protezionecivile.gov.it">Protezione Civile</a>)</h5>
                <iframe 
                    src="/radar_pc" 
                    style={{border: 0}} 
                    name="radar_pc" 
                    scrolling="no" 
                    height={height/2 + "px"} 
                    width="100%"
                >
                </iframe>
            </div>
            */
            }

            <div className="radar-box">
                <h5>(Fonte radar: <a href="http://www.meteociel.fr" target='_blank'>Meteociel</a>)</h5>
                <img 
                    style={{width: "100%"}}
                    src="https://www.meteociel.fr/cartes_obs/radar/lastradar_it_s.gif" 
                    title='meteociel-radar-rain'
                />    
            </div>
            <hr style={{margin: "12px 0"}}/>
            <div className="radar-box">
                <h5>(Fonte radar: <a href="http://www.meteociel.fr" target='_blank'>Meteociel</a>)</h5>
                <img 
                    style={{width: "100%"}}
                    src="https://www.meteociel.fr/obs/foudre/anim2h_it.gif" 
                    title='meteociel-radar'
                />    
            </div>

        </div>
    )
}

export default Radar
