import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Button } from './Button';
import { CircularProgress, TextField } from '@material-ui/core'
import './StationPage.css';
import ReactGA from 'react-ga4'

import { Crypto } from '../scripts/crypto'
import axios from 'axios';
import StationChart from './StationChart';
import { valueInJSON, windDir_Degree_To_Text, windDir_Text_To_Degree } from '../scripts/functions';
import Requests from '../scripts/Requests';
import ArchiveTable from './ArchiveTable';
import MonthlyArchiveTable from './MonthlyArchiveTable';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function StationPage() {

    const history = useHistory();
    let { id } = useParams();
    // QUERY PARAMS
    let query = useQuery();

    //GET _ID FROM API
    const [_id, setId] = useState(id);
    //GET STATION DATA FROM PRIVATE API
    const [api, setApi] = useState({});
    //STATION STATUS
    const [status, setStatus] = useState(false);

    //GET JSON FROM STATION LIST
    const [json, setJson] = useState({});

    //CHECK DATA IS LOADED
    const [dataLoaded, setDataLoaded] = useState(false);

    //BANNER CLIPBOARD
    const [clipboard, setClipboard] = useState(false);

    // SHOW ARCHIVE
    const [showArchive, setShowArchive] = useState(false)

    // SHOW DAILY CHART
    const [showChart, setShowChart] = useState(false)


    async function getStationData (id){
        Requests.get_station_info_by_id(id)
        .then(res => {
            //let api = JSON.parse(Crypto.decode(res.data));
            //let apiStation = api.filter((item) => item.stationId === station.stationId)[0];

            setJson(res);
            setApi(res);
        })
        .catch(err => console.log(err));


        Requests.get_station_live_by_id(id)
            .then(res => {
                //let data = JSON.parse(Crypto.decode(res.data));
                //let station = data.filter((item) => item.id === _id)[0];
                //console.log(station);
                setStatus(res.status);
                setJson(res)
            })
            .catch(err => console.log(err));
        //getData();
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Station page" });

        if (query.get("tabs") === "archive") {
            setShowArchive(true)
        }


        getStationData(id)
        .then(res => {
            setDataLoaded(true);
        })
        .catch(err => console.log(err))

        return () => { }

    }, [])

    function getImage(type){
        if("img" in api){
            switch(type){
                case "station":
                    if("station" in api.img){
                        if(api.img.station.startsWith("http")){
                            return api.img.station
                        }
                    }
                    break
                case "enviroment":
                    if("enviroment" in api.img){
                        if(api.img.enviroment.startsWith("http")){
                            return api.img.enviroment
                        }
                    }
                    break
                default:
                    return '../img/noimage.jpg'
            }
        }

        return '../img/noimage.jpg'
    }

    function copyToClipboard() {
        let text = '<a href="https://calabriaweatherdata.com/"><img src="https://calabriaweatherdata.com/banner?id=' + api.stationId + '" alt="cwd_banner" /></a>'
        navigator.clipboard.writeText(text);
        setClipboard(true);
    }

    return (!dataLoaded ?
        (
            <div style={{ minHeight: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        )
        :
        (
            <div className="page-container">

                <div className="page-commands">
                    <Button buttonStyle="btn--primary" onClick={() => history.goBack() } noLink>
                        INDIETRO
                    </Button>
                    <span className="page-title">
                        <p>STAZIONE METEO DI </p>
                        <h2 className="station-title">
                            {json.locality ? json.locality + " - " : ""}{json.city}
                        </h2>
                    </span>

                </div>

                <hr className="separator" />

                <div className="page-data">
                    <div className="station-data">
                        <span className="station-data-title">
                            <h4>DATI LIVE <p style={{ color: status ? "lime" : "red", display: "inline" }}>{status ? "(online)" : "(offline)"}</p></h4>
                            <span className="station-data-update">
                                <p style={{ marginRight: 5 }}>Ultimo aggiornamento:</p>
                                <p style={{ fontWeight: "bold", fontFamily: "Helvetica" }}>
                                    {json.updateTime ? " " + json.updateTime : "Undefined"}
                                    {json.updateDate ? " - " + json.updateDate : ""}
                                </p>
                            </span>
                        </span>
                        <div className="station-row">

                            <div className="station-data-item station-data-large">
                                <span className='station-data-current'>
                                    <div className="station-data-icon" style={{ backgroundImage: "url('/img/station-page/temp.png')" }}></div>
                                    <p className="now">{valueInJSON("temp", json) ? json.temp + "°C" : "NO DATA"}</p>
                                </span>
                                <p className="station-data-item-title">TEMPERATURA</p>
                                <div className='station-data-other'>
                                    <span className='station-data-block'>
                                        {(valueInJSON("tempHigh", json)) &&
                                            <div className="station-data-row station-data-max">
                                                <i className='fa fa-chevron-up'></i>
                                                <span>
                                                    <p>MAX</p>
                                                    <p>{json.tempHigh}° C</p>
                                                </span>
                                            </div>
                                        }
                                        {(valueInJSON("tempLow", json)) &&
                                            <div className="station-data-row station-data-min">

                                                <i className='fa fa-chevron-down'></i>
                                                <span>
                                                    <p>MIN</p>
                                                    <p>{json.tempLow}° C</p>
                                                </span>
                                            </div>
                                        }
                                    </span>
                                    <span className='station-data-block'>
                                        {false && (valueInJSON("excursion", json)) &&
                                            <div className="station-data-row station-data-media">
                                                <i className="fa fa-arrow-trend-up"></i>
                                                <span>
                                                    <p>MED</p>
                                                    <p>26.0 °C</p>
                                                </span>
                                            </div>
                                        }
                                        {(valueInJSON("excursion", json)) &&
                                            <div className="station-data-row station-data-excursion">
                                                <i className="fa fa-up-right-and-down-left-from-center"></i>
                                                <span>
                                                    <p>ESC</p>
                                                    <p>{json.excursion}° C</p>
                                                </span>
                                            </div>
                                        }
                                    </span>
                                </div>

                            </div>
                            <div className="station-data-item">
                                <span className='station-data-current'>
                                    <div className="station-data-icon" style={{ backgroundImage: "url('/img/station-page/humidity.png')" }}></div>
                                    <p className="now">{valueInJSON("humidity", json) ? parseInt(json.humidity) + "%" : "NO DATA"}</p>
                                </span>
                                <p className="station-data-item-title">UMIDITA' RELATIVA</p>
                                <span className='station-data-block'>
                                    {(valueInJSON("humidityHigh", json)) &&
                                        <div className="station-data-row station-data-min">
                                            <i className='fa fa-chevron-up'></i>
                                            <span>
                                                <p>MAX</p>
                                                <p>{parseInt(json.humidityHigh)}%</p>
                                            </span>
                                        </div>
                                    }
                                    {(valueInJSON("humidityLow", json)) &&
                                        <div className="station-data-row station-data-max">

                                            <i className='fa fa-chevron-down'></i>
                                            <span>
                                                <p>MIN</p>
                                                <p>{parseInt(json.humidityLow)}%</p>
                                            </span>
                                        </div>
                                    }
                                </span>
                            </div>

                            <div className="station-data-item">
                                <span className='station-data-current'>
                                    <div className="station-data-icon" style={{ backgroundImage: "url('/img/station-page/temp.png')" }}></div>
                                    <p className="now">{valueInJSON("dewpoint", json) ? json.dewpoint + "°C" : "NO DATA"}</p>
                                </span>
                                <p className="station-data-item-title">DEWPOINT</p>
                                <span className='station-data-block'>
                                    {(valueInJSON("dewpointHigh", json)) &&
                                        <div className="station-data-row station-data-max">
                                            <i className='fa fa-chevron-up'></i>
                                            <span>
                                                <p>MAX</p>
                                                <p>{json.dewpointHigh}°C</p>
                                            </span>
                                        </div>
                                    }
                                    {(valueInJSON("dewpointLow", json)) &&
                                        <div className="station-data-row station-data-min">
                                            <i className='fa fa-chevron-down'></i>
                                            <span>
                                                <p>MIN</p>
                                                <p>{json.dewpointLow}°C</p>
                                            </span>
                                        </div>
                                    }
                                </span>
                            </div>


                            <div className="station-data-item ">
                                <span className='station-data-current'>
                                    <div className="station-data-icon" style={{ backgroundImage: "url('/img/station-page/rainy.png')" }}></div>
                                    <p className="now">{valueInJSON("windSpeed", json) ? json.precipTotal + " mm" : "NO DATA"}</p>
                                </span>
                                <p className="station-data-item-title">PRECIPITAZIONI</p>
                                <span className='station-data-block'>
                                    {(valueInJSON("precipRate", json)) &&
                                        <div className="station-data-row">
                                            <i className='fa fa-chevron-up'></i>
                                            <span>
                                                <p>RATE</p>
                                                <p>{json.precipRate} mm</p>
                                            </span>
                                        </div>
                                    }
                                    {(valueInJSON("precipMonth", json)) &&
                                        <div className="station-data-row">
                                            <i className='fa fa-chevron-up'></i>
                                            <span>
                                                <p>MESE</p>
                                                <p>{json.precipMonth} mm</p>
                                            </span>
                                        </div>
                                    }

                                    {(valueInJSON("precipYear", json)) &&
                                        <div className="station-data-row">
                                            <i className='fa fa-chevron-up'></i>
                                            <span>
                                                <p>ANNO</p>
                                                <p>{json.precipYear} mm</p>
                                            </span>
                                        </div>
                                    }
                                </span>
                            </div>

                        </div>
                        <div className="station-row">

                            <div className={"station-data-item station-data-large"}>
                                <span className='station-data-current'>
                                    <div className="station-data-icon" style={{ backgroundImage: "url('/img/station-page/barometer.png')" }}></div>
                                    <p className="now">{
                                        valueInJSON("temp", json) ?
                                            parseFloat(json.pressure).toFixed(1) + " hPa"
                                            :
                                            "NO DATA"
                                    }
                                    </p>
                                </span>
                                <p className="station-data-item-title">PRESSIONE</p>
                                <div className='station-data-other'>
                                    <span className='station-data-block'>
                                        {(valueInJSON("pressureHigh", json)) &&
                                            <div className="station-data-row station-data-max">
                                                <i className='fa fa-chevron-up'></i>
                                                <span>
                                                    <p>MAX</p>
                                                    <p>{parseFloat(json.pressureHigh).toFixed(1)} hPa</p>
                                                </span>
                                            </div>
                                        }
                                        {(valueInJSON("pressureLow", json)) &&
                                            <div className="station-data-row station-data-min">

                                                <i className='fa fa-chevron-down'></i>
                                                <span>
                                                    <p>MIN</p>
                                                    <p>{parseFloat(json.pressureLow).toFixed(1)} hPa</p>
                                                </span>
                                            </div>
                                        }
                                    </span>
                                    { false &&
                                    <span className='station-data-block'>
                                        {(valueInJSON("excursion", json)) &&
                                            <div className="station-data-row station-data-media">
                                                <i className="fa fa-arrow-trend-up"></i>
                                                <span>
                                                    <p>MED</p>
                                                    <p>1015.5 hPa</p>
                                                </span>
                                            </div>
                                        }
                                    </span>
                                    }
                                </div>

                            </div>


                            <div className="station-data-item ">
                                <span className='station-data-current'>
                                    <div className="station-data-icon" style={{ backgroundImage: "url('/img/station-page/wind.png')" }}></div>
                                    <p className="now">{valueInJSON("windSpeed", json) ? json.windSpeed + " km/h" : "NO DATA"}</p>
                                </span>
                                <p className="station-data-item-title">VELOCITA' VENTO</p>
                                <span className='station-data-block'>
                                    {(valueInJSON("humidityHigh", json)) &&
                                        <div className="station-data-row station-data-max">
                                            <i className='fa fa-chevron-up'></i>
                                            <span>
                                                <p>MAX</p>
                                                <p>{json.windSpeedHigh} km/h</p>
                                            </span>
                                        </div>
                                    }
                                </span>
                            </div>



                            <div className="station-data-item ">
                                <span className='station-data-current'>
                                    <div className="station-data-icon" style={{ backgroundImage: "url('/img/station-page/wind-rose.png')" }}></div>
                                    <p className="now">
                                        {
                                            valueInJSON("windDir", json) ?
                                                (
                                                    (typeof json.windDir === "string" && isNaN(json.windDir))
                                                        ?
                                                        windDir_Text_To_Degree(json.windDir) + "° " + json.windDir
                                                        :
                                                        parseInt(json.windDir) + "° " + windDir_Degree_To_Text(json.windDir)
                                                )
                                                :
                                                "NO DATA"
                                        }
                                    </p>
                                </span>
                                <p className="station-data-item-title">DIREZIONE VENTO</p>
                                <span className='station-data-block'>
                                </span>
                            </div>


                            <div className='data-buttons'>
                                <Button
                                    buttonStyle={"btn--primary"}
                                    buttonSize={"btn--medium"}
                                    fullWidth
                                    onClick={() => setShowChart(prev => !prev)}
                                >
                                    GRAFICO 24h <i className={showChart ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i>
                                </Button>
                                <Button
                                    buttonStyle={"btn--outline"}
                                    buttonSize={"btn--medium"}
                                    fullWidth
                                    onClick={() => setShowArchive(prev => !prev)}
                                >
                                    ARCHIVIO <i className={showArchive ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="separator" />

                {showChart &&
                    <>
                        <div className="chart-title">
                            <h3>GRAFICI 24 ORE</h3>
                            <p>Clicca sui parametri per disattivarli</p>
                        </div>
                        {json.stationId ?
                            <StationChart stationId={json.stationId}></StationChart>
                            :
                            <h5>NON DISPONIBILE</h5>
                        }
                        <hr className="separator" />
                    </>
                }



                {json && showArchive &&
                    /*
                    <>
                        <ArchiveTable stationId={json.stationId}></ArchiveTable>
                        <hr className="separator" />
                    </>
                    */
                    <>
                        <MonthlyArchiveTable stationId={json.stationId}></MonthlyArchiveTable>
                        <hr className="separator" />
                    </>
                }

                <div className="page-ubicazione">
                    <div
                        className="station-image"
                        style={{ backgroundImage: "url("+getImage("station") + ")"}}>
                    </div>
                    <div className="ubicazione-station">
                        <h4 className="ubicazione-title">ANAGRAFICA STAZIONE</h4>
                        <ul className="ubicazione-list">
                            <li>
                                <p>MODELLO: </p>
                                <p>{api.stationName}</p>
                            </li>
                            <li>
                                <p>ALTITUDINE: </p>
                                <p>{api.altitude ? api.altitude + "m" : "-"}</p>
                            </li>
                            <li>
                                <p>INSTALLAZIONE: </p>
                                <p>{api.position ? api.position : "-"}</p>
                            </li>
                        </ul>

                        <h4 className="ubicazione-title">DESCRIZIONE STAZIONE</h4>
                        <span className="ubicazione-description">
                            {!api.description ? `NO DESCRIPTION` : api.description}
                        </span>

                        {"website" in api &&
                            <>
                                <h4 className="ubicazione-title">SITO WEB</h4>
                                <span className="ubicazione-url">
                                    <a href={api.website} target="_blank">
                                        {api.website}
                                    </a>
                                </span>
                            </>
                        }
                    </div>
                </div>

                <hr className="separator" />

                <div className="page-microclima">
                    <div
                        className="microclima-image"
                        style={{ backgroundImage: "url("+getImage("enviroment") + ")"}}>
                    </div>
                    <div className="microclima-block">
                        <h4 className="microclima-title">MICROCLIMA</h4>
                        <p className="microclima-desc">
                            {!api.microclima ? `NO MICROCLIMA` : api.microclima}
                        </p>
                    </div>


                </div>

                <hr className="separator" />

                <div className="page-widget">
                    <div className="widget-title">
                        <h4>BANNER</h4>
                        <p>Inserisci il banner (500x150px) sul tuo sito</p>
                        <TextField
                            id="outlined-textarea"
                            label="Codice HTML"
                            margin="normal"
                            style={{ width: "100%" }}
                            disabled
                            defaultValue={'<a href="https://' + window.location.host + '">\n\t<img src="' + process.env.REACT_APP_BACKEND + '/banner/' + id + '" alt="cwd_banner"/>\n</a>'}
                            multiline
                            variant="outlined"
                        />
                        {clipboard && <p>Copiato con successo!</p>}
                        <Button buttonStyle="btn--primary" onClick={() => copyToClipboard()}>
                            COPIA
                        </Button>
                    </div>

                    <div className="widget-banner">
                        <img src={process.env.REACT_APP_BACKEND + "/banner/" + id} alt="cwd_banner" />
                    </div>

                </div>
            </div>
        )
    )
}

export default StationPage;
