import React, { useEffect, useCallback, useRef } from 'react'
import FileDragDrop from '../components/FileDragDrop'

import "./Report.scss"
import { Button } from '../components/Button';
import { useState } from 'react';
import Webcam from 'react-webcam';
import { Autocomplete, Box, Checkbox, CircularProgress, FormControl, FormGroup, IconButton, InputAdornment, InputLabel, Button as MUIButton, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { FormControlLabel } from '@mui/material';
import Requests from '../scripts/Requests';
import ReCAPTCHA from 'react-google-recaptcha';

function Report() {

    const captchaActive = true;
    const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY
    const comuneRef = useRef()

    const [selectedComune, setSelectedComune] = useState(null);
    const [cameraOpen, setCameraOpen] = useState(false)
    const [imgSrc, setImgSrc] = React.useState(null);
    const [comuni, setComuni] = useState([])
    const [sendLoading, setSendLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [captcha, setCaptcha] = useState(false)

    const [locality, setLocality] = useState("")
    const [localization, setLocalization] = useState(null)
    const [inLocalization, setInLocalization] = useState(false)

    const onUploadEvent = (file) => {
        //console.log(file);
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64String = event.target.result;
            //console.log(base64String);
            setImgSrc(base64String)
        };
        reader.readAsDataURL(file)
    };

    function getCurrentFormattedTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Mese è 0-based
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');

        return `${year}${month}${day}_${hours}${minutes}`;
    }

    const downloadImg = () => {
        var a = document.createElement("a"); //Create <a>
        a.href = imgSrc; //Image Base64 Goes here
        a.download = "report_" + getCurrentFormattedTime() + ".jpg";
        a.click();
    }

    const handleSubmit = (event) => {
        setSendLoading(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (selectedComune && localization) {
            console.log(data);
            let payload = {
                "author": data.get('author').toString(),
                "city": localization['osmtags']['name'],
                "locality": localization['city'],
                "img": imgSrc,
                "lat": localization['latt'],
                "lng": localization['longt'],
                "filename": localization['city'] + "_" + localization['osmtags']['name'] + "_" + ".jpg",
                "captcha": captcha
            }

            Requests.send_new_report(payload)
                .then(res => {
                    setSendLoading(false)
                    setComplete(true)
                })
                .catch(err => {
                    setSendLoading(false)
                    console.log(err);
                })
        } else if (selectedComune) {
            Requests.localizate_comune(selectedComune)
                .then(res => {
                    if ("latt" in res && "longt" in res) {
                        let payload = {
                            "author": data.get('author').toString(),
                            "city": selectedComune,
                            "locality": locality,
                            "img": imgSrc,
                            "lat": res['latt'],
                            "lng": res['longt'],
                            "filename": locality + "_" + selectedComune + "_" + ".jpg",
                            "captcha": captcha
                        }
                        console.log(payload);

                        Requests.send_new_report(payload)
                            .then(res => {
                                setSendLoading(false)
                                setComplete(true)
                            })
                            .catch(err => {
                                setSendLoading(false)
                                console.log(err);
                            })

                    }
                })
                .catch(err => console.log(err))
        }
    };

    const recaptchaChange = (value) => {
        setCaptcha(value)
    }

    const localizate = () => {
        if (localization) {
            setLocalization(null)
            return
        }

        setInLocalization(true)
        navigator.geolocation.getCurrentPosition(
            (geo) => {
                console.log(geo);
                Requests.get_city_by_coords(geo.coords.latitude, geo.coords.longitude)
                    .then((res) => {
                        setSelectedComune(res['osmtags']['name'])
                        setLocalization(res)
                        setInLocalization(false)
                    })
                    .catch((e) => {
                        setInLocalization(false)
                    })
            },
            (err) => {
                console.log(err);
                setInLocalization(false)
            }
        )
    }

    useEffect(() => {
        Requests.search_comuni()
            .then(res => {
                let toJSON = JSON.parse(res)
                let labels = toJSON.map(el => el.denominazione_in_italiano)
                setComuni(labels)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <div className='report-container'>
            <div className='report-title'>
                <h1>SEGNALAZIONI LIVE</h1>
                {imgSrc && <h3>(Compila il form per l'invio)</h3>}
            </div>

            {
                cameraOpen ?

                    <CameraOverlay setImage={setImgSrc} cameraClose={setCameraOpen} />

                    :
                    imgSrc ?
                        <div className='image-form'>
                            <div className='checked-image'>
                                <img src={imgSrc} />
                                <span className='checked-image-buttons'>
                                    <a onClick={() => downloadImg()}>
                                        <i className="fa fa-circle-down"></i></a>
                                    <a onClick={() => {
                                        setImgSrc(null)
                                        setComplete(false)
                                    }}>
                                        <i className="fa fa-trash-can"></i>
                                    </a>
                                </span>
                            </div>
                            {
                                sendLoading ?
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    !complete ?
                                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                            <Typography variant="h6" align='center'>
                                                Dati
                                            </Typography>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="author"
                                                label="Autore"
                                                name="author"
                                                autoFocus
                                            />
                                            <Typography variant="h6" mt={2} align='center'>
                                                Posizione
                                            </Typography>
                                            <FormControl variant="outlined" >
                                                <InputLabel htmlFor="outlined-locality">Località</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-locality"
                                                    label="Località"
                                                    type={'text'}
                                                    disabled={inLocalization}
                                                    fullWidth
                                                    value={localization ? localization.city : locality}
                                                    onChange={(e) => {
                                                        setLocality(e.target.value) // Aggiorna la variabile con l'elemento selezionato
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="localizate"
                                                                onClick={() => localizate()}
                                                                edge="end"
                                                            >
                                                                {
                                                                    localization ?
                                                                        <i className="fa fa-close"></i>
                                                                        :
                                                                        inLocalization ?
                                                                            <CircularProgress />
                                                                            :
                                                                            <i className="fa fa-location-dot"></i>
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }

                                                />
                                            </FormControl>
                                            <Autocomplete
                                                id="comuni"
                                                options={comuni}
                                                ref={comuneRef}

                                                value={localization ? localization['osmtags']['name'] : selectedComune}
                                                onChange={(event, newValue) => {
                                                    setSelectedComune(newValue) // Aggiorna la variabile con l'elemento selezionato
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        label="Comune"
                                                        variant="outlined"
                                                        required
                                                    />
                                                )}
                                            />
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox required value="check-informativa" color="primary" />}
                                                    label={<Typography>
                                                        Ho letto l'{' '}
                                                        <a href={process.env.REACT_APP_BACKEND + "/api/privacy_policy"} target="_blank" rel="noopener noreferrer">
                                                            Informativa
                                                        </a>
                                                    </Typography>
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox required value="check-geolocalizzazione" color="primary" />}
                                                    label={<Typography>
                                                        Consenso al servizio di geolocalizzazione
                                                    </Typography>
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox required value="check-eta" color="primary" />}
                                                    label={<Typography>
                                                        Dichiaro di avere più di 18 anni
                                                    </Typography>
                                                    }
                                                />
                                            </FormGroup>
                                            {captchaActive &&
                                                <div className="recaptcha-reports">
                                                    <ReCAPTCHA
                                                        sitekey={RECAPTCHA_KEY}
                                                        onChange={recaptchaChange}
                                                        onExpired={() => setCaptcha(false)}
                                                    />
                                                </div>
                                            }
                                            {
                                                captchaActive ?
                                                    captcha &&
                                                    <MUIButton
                                                        style={{ marginTop: "8px" }}
                                                        type={"submit"}
                                                        variant="contained"
                                                        endIcon={<i className="fa fa-paper-plane" style={{ fontSize: "12px" }}></i>}
                                                    >
                                                        Invia
                                                    </MUIButton>


                                                    :
                                                    <MUIButton
                                                        type={"submit"}
                                                        variant="contained"
                                                        endIcon={<i className="fa fa-paper-plane" style={{ fontSize: "12px" }}></i>}
                                                    >
                                                        Invia
                                                    </MUIButton>
                                            }


                                        </Box>
                                        :
                                        <div className='report-send-complete'>
                                            <h3>Inviato con successo!</h3>
                                        </div>
                            }
                        </div>
                        :

                        <div className='image-picker'>
                            <FileDragDrop onUpload={onUploadEvent} />
                            <Button
                                buttonSize={"btn--large"}
                                buttonStyle={"btn--primary"}
                                className={"report-picture-btn"}
                                onClick={() => {
                                    setCameraOpen(true)
                                }}
                                fullWidth
                            >
                                <i className="fa fa-camera"></i> SCATTA UNA FOTO
                            </Button>
                        </div>

            }
        </div>
    )
}

const CameraOverlay = ({ setImage, cameraClose }) => {

    const [img, setImg] = useState(false)
    const [frontCamera, setFrontCamera] = useState(true);
    const cameraRef = React.useRef(null);
    const capture = useCallback(() => {
        const imageSrc = cameraRef.current.getScreenshot();
        setImage(imageSrc);
        setImg(imageSrc)
    }, [cameraRef, setImage]);

    return (
        <div className='cwd-camera'>
            {
                img ?
                    <div className='image-preview'>
                        <img src={img} />
                        <span>
                            <a onClick={() => setImg(null)}><i className="fa fa-close"></i></a>
                            <a onClick={() => cameraClose(prev => !prev)}><i className="fa fa-check"></i></a>
                        </span>
                    </div>
                    :
                    <>
                        <Webcam
                            audio={false}
                            allowFullScreen
                            mirrored={frontCamera}
                            videoConstraints={
                                { facingMode: frontCamera ? "user" : { exact: "environment" } }
                            }
                            ref={cameraRef}
                            screenshotFormat="image/jpeg"
                        />
                        <div className='camera-buttons'>
                            <span></span>
                            <button className='btn-take-picture' onClick={capture}></button>
                            <a className="btn-rotate-camera" onClick={() => setFrontCamera(prev => !prev)}>
                                <i className="fa fa-arrows-rotate"></i>
                            </a>
                        </div>

                    </>
            }

        </div>
    )
}

export default Report