import React,{useState, useEffect} from 'react'
import {Switch, CircularProgress} from '@material-ui/core';

import './StationsItem.css';
import requestStation from '../scripts/request_station';

function StationsItem(props) {
    const [obj, setObj] = useState({}); //Dati stazione
    const [hide, setHide] = useState(true); //Nascondi dati
    //const [active, setActive] = useState(true); //Stazione attiva e funzionante?

    const [dataLoaded, setDataLoaded] = useState(false);
    const [inError, setInError] = useState(false);
    
    useEffect(() => {
        loadData();
    }, [])
    
    const loadData = ()=>{
       if(props.obj !== null) {
           setObj(props.obj);
           setDataLoaded(true);
        }else{
           console.log("Dati già caricati!");
        }
    }
   
    return (
        <li className="item-container">
            <div className="item-bar">
                <div style={{display:"flex", flexDirection:'row', alignItems:'center'}}>
                    <i className={"fas fa-circle item-status " + ( obj.status ? "green" : " red")}></i>
                    <p className="item-name">
                        <a href={"/station/"+obj.id}>
                            {obj.city}{obj.locality ? " - " + obj.locality : null}
                        </a>
                    </p>
                </div>
                <div onClick={()=>setHide(!hide)}>
                    <Switch
                        checked={!hide}
                        onChange={loadData}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
            </div>
            
            
            <div className={"item-data " + (hide ? "hide" : "")}>
                {dataLoaded && !inError &&
                <div className="item-data-list">

                    {("updateTime" in obj || "updateDate" in obj) &&
                        <span className={"item-data-update"}>
                        <span className="item-data-update-title">
                            <span style={{width: "32px", textAlign: "center"}}>
                                <i className="far fa-clock item-data-icon"></i>
                            </span>
                            
                            <p>Ultimo Aggiornamento:</p>
                        </span>
                        <span className="item-data-update-info">
                            <p>
                                {(obj.updateTime !== null ? obj.updateTime : null )}
                            </p>
                            <p>
                                {(obj.updateDate !== null ? obj.updateDate : null)}
                            </p>
                        </span>
                    </span>
                    }
                    { "temp" in obj &&
                    <span className={"item-data-item " + (obj.temp !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-temperature-low item-data-icon"></i>
                            </span>
                            
                            <p>TEMPERATURE</p>
                        </span>
                        <p>{obj.temp}° C</p>
                    </span>
                    }
                    {"dewpoint" in obj &&
                    <span className={"item-data-item " + (obj.dewpoint !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-fire-alt item-data-icon"></i>
                            </span>
                            
                            <p>DEWPOINT</p>
                        </span>
                        <p>{obj.dewpoint}° C</p>
                    </span>
                    }
                    {"pressure" in obj &&
                    <span className={"item-data-item " + (obj.pressure !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-tachometer-alt item-data-icon"></i>
                            </span>
                            
                            <p>PRESSION</p>
                        </span>
                        <p>{obj.pressure} hPa</p>
                    </span>
                    }
                    {"humidity" in obj &&
                    <span className={"item-data-item " + (obj.humidity !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-tint item-data-icon"></i>
                            </span>
                            <p>HUMIDITY</p>
                        </span>
                        <p>{obj.humidity}%</p>
                    </span>
                    }
                    {"windSpeed" in obj &&
                    <span className={"item-data-item " + (obj.windSpeed !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-wind item-data-icon"></i>
                            </span>
                            <p>WIND SPEED</p>
                        </span>
                        <p>{obj.windSpeed} km/h</p>
                    </span>
                    }
                    {"windDir" in obj &&
                    <span className={"item-data-item " + (obj.windDir !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-compass item-data-icon"></i>
                            </span>
                            <p>WIND DIRECTION</p>
                        </span>
                        <p>{obj.windDir}°</p>
                    </span>
                    }
                    {"windGust" in obj &&
                    <span className={"item-data-item " + (obj.windGust !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-flag item-data-icon"></i>
                            </span>
                            <p>WIND GUST</p>
                        </span>
                        <p>{obj.windGust} km/h</p>
                    </span>
                    }
                    {"precipRate" in obj &&
                    <span className={"item-data-item " + (obj.precipRate !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-cloud-rain item-data-icon"></i>
                            </span>
                            <p>RAIN RATE</p>
                        </span>
                        <p>{obj.precipRate} mm/h</p>
                    </span>
                    }
                    {"precipTotal" in obj &&
                    <span className={"item-data-item " + (obj.precipTotal !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-water item-data-icon"></i>
                            </span>
                            <p>RAIN TOTAL</p>
                        </span>
                        <p>{obj.precipTotal} mm</p>
                    </span>
                    }
                    {"heatIndex" in obj &&
                    <span className={"item-data-item " + (obj.heatIndex !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-fire item-data-icon"></i>
                            </span>
                            <p>HEAT INDEX</p>
                        </span>
                        <p>{obj.heatIndex}° C</p>
                    </span>
                    }
                    {"uv" in obj &&
                    <span className={"item-data-item " + (obj.uv !== null ? "" : "hide")}>
                        <span style={{display: "flex", width: 'auto'}}>
                            <span style={{width: "32px", textAlign: "center", marginRight:"10px"}}>
                                <i className="fas fa-sun item-data-icon"></i>
                            </span>
                            <p>UV</p>
                        </span>
                        <p>{obj.uv}</p>
                    </span>
                    }
                </div>
                }
                {!dataLoaded &&
                    <div className={"progress-wrapper"}><CircularProgress size={18}/></div>
                }
                {inError &&
                    <div className={"error-wrapper"}>
                        <i className="fas fa-times" style={{color: "red", fontSize:"18px", padding:"5px"}}></i>
                        Errore caricamento dati.
                    </div>
                }
            </div>
            
        </li>
    )
}

export default StationsItem;
