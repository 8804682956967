export function valueInJSON(value, json) {
    if (value in json) {
        if (json[value]) return isNaN(json[value]) ? false : true;
    }
    return false;
}

export function validateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}


export function celsiusToFahrenheit(temp) {
    return parseFloat((temp * (9 / 5)) + 32).toFixed(1);
}

export function fahrenheitToCelsius(temp) {
    return parseFloat((temp - 32) * (5 / 9)).toFixed(1);
}


export function dewpoint(temp, humidity) {
    temp = parseFloat(temp);
    humidity = parseFloat(humidity);
    let Es = 6.11 * 10 ** [(7.5 * temp) / (237.7 + temp)];
    let E = (humidity * Es) / 100;
    let R = [-430.22 + 237.7 * Math.log(E)] / [-Math.log(E) + 19.08];
    return parseFloat(R).toFixed(2);
}


export function heatIndex(t, h) {
    t = celsiusToFahrenheit(t);
    let r = -42.379 + 2.04901523 * t + 10.14333127 * h - 0.22475541 * t * h - 0.00683783 * (t ** 2) - 0.05481717 * (h ** 2) + 0.00122874 * (t ** 2) * h + 0.00085282 * t * (h ** 2) - 0.00000199 * (t ** 2) * (h ** 2);
    return fahrenheitToCelsius(r);
}


export function inhgToHpa(pression) {
    return parseFloat(pression / 0.029529983071445).toFixed(2);
}

export function inToMm(quantity) {
    return parseFloat((25.4 * quantity)).toFixed(2);
}

export function mphToKmh(quantity) {
    return parseFloat((1.60934 * quantity)).toFixed(2);
}

export function jsonTemplate() {
    let obj = {};
    obj['dewpoint'] = "-";
    obj['stationId'] = "-";
    obj['city'] = "-";
    obj['last_update'] = "-";
    obj['temp'] = "-";
    obj['heatIndex'] = "-";
    obj['windSpeed'] = "-";
    obj['windChill'] = "-";
    obj['windGust'] = "-";
    obj['windDir'] = "-";
    obj['pressure'] = "-";
    obj['precipRate'] = "-";
    obj['precipTotal'] = "-";
    obj['humidity'] = "-";
    obj['uv'] = "-";
    return obj;
}

export function windDir_Text_To_Degree(text){
    switch(text){
        case "N":
            return 0
        case "NE":
            return 45
        case "E":
            return 90
        case "SE":
            return 135
        case "S":
            return 180
        case "SW":
            return 225
        case "W":
            return 270
        case "NW":
            return 315
        case "NNE":
            return 22
        case "ENE":
            return 67
        case "ESE":
            return 112
        case "SSE":
            return 157
        case "SSW":
            return 202
        case "WSW":
            return 247
        case "WNW":
            return 292
        case "NNW":
            return 337 
        default:
            return -1
    }
}

export function windDir_Degree_To_Text(degree){
    if(degree > 11 && degree < 34){
        return "NNE"
    }else if(degree >= 34 && degree < 57){
        return "NE"
    }else if(degree >= 57 && degree < 79){
        return "ENE"
    }else if(degree >= 79 && degree < 102){
        return "E"
    }else if(degree >= 102 && degree < 124){
        return "ESE"
    }else if(degree >= 124 && degree < 147){
        return "SE"
    }else if(degree >= 147 && degree < 169){
        return "SSE"
    }else if(degree >= 169 && degree < 192){
        return "S"
    }else if(degree >= 192 && degree < 214){
        return "SSW"
    }else if(degree >= 214 && degree < 237){
        return "SW"
    }else if(degree >= 237 && degree < 259){
        return "WSW"
    }else if(degree >= 259 && degree < 282){
        return "W"
    }else if(degree >= 282 && degree < 304){
        return "WNW"
    }else if(degree >= 304 && degree < 326){
        return "NW"
    }else if(degree >= 327 && degree < 349){
        return "NNW"
    }else{
        return "N"
    }
}

function snowColor(val) {
    //let v = parseInt(val);
    return ["#BBDDFF", "#0087DD"]
}

export function switchFontColors(type, value) {
    switch (type) {
        case "temp":
            if (value > 32) return "#dbdbdb";
            break;
        case "tempHigh":
            if (value > 30) return "#dbdbdb";
            break;
        case "pressure":
            break;
        case "excursion":
            break;
        case "tempLow":
            break;
        case "dewpoint":
            break;
        case "humidity":
            if (value > 70) return "#dbdbdb";
            break;
        case "precipTotal":
            if(value >= 30) return "#dbdbdb";
            break;
        case "windSpeed":
            break;
        case "windGustHigh":
            if (value > 50) return "#dbdbdb";
            break;
        case "snow":
            break;
        default:
            return "#000";
    }

    return "#000";
}

export function switchColors(type, v, border = 0) {

    switch (type) {
        case "temp":
            return tempColor(v)[border];
        case "pressure":
            return pressureColor(v)[border];
        case "excursion":
            return escursioneColor(v)[border];
        case "tempLow":
            return tempColor(v)[border];
        case "tempHigh":
            return tempColor(v)[border];
        case "dewpoint":
            return dewpointColor(v)[border];
        case "humidity":
            return humidityColor(v)[border];
        case "precipTotal":
            return precipColor(v)[border];
        case "windSpeed":
            return windSpeed(v)[border];
        case "windGustHigh":
            return windSpeed(v)[border];
        case "snow":
            return snowColor(v)[border];
        //case "precipMonth":
        //    return precipMonth(v);
        //case "precipYear":
        //    return precipYear(v);
        default:
            return "#009EFF";
    }
}


function tempColor(temp) {
    let v = parseInt(temp);

    if (v >= 44) return ["#fa99ff", "#000000"];
    else if (v >= 42 && v < 44) return ["#ff65b9", "#000000"];
    else if (v >= 40 && v < 42) return ["#db0099", "#000000"];
    else if (v >= 38 && v < 40) return ["#a5004a", "#000000"];
    else if (v >= 36 && v < 38) return ["#ce0029", "#000000"];
    else if (v >= 34 && v < 36) return ["#ce0029", "#000000"];
    else if (v >= 32 && v < 34) return ["#ff3a00", "#000000"];
    else if (v >= 30 && v < 32) return ["#ff6300", "#000000"];
    else if (v >= 28 && v < 30) return ["#ff8400", "#000000"];
    else if (v >= 26 && v < 28) return ["#ffa500", "#000000"];
    else if (v >= 24 && v < 26) return ["#ffb500", "#000000"];
    else if (v >= 22 && v < 24) return ["#ffce00", "#000000"];
    else if (v >= 20 && v < 22) return ["#ffde00", "#000000"];
    else if (v >= 18 && v < 20) return ["#ffef00", "#000000"];
    else if (v >= 16 && v < 18) return ["#efff00", "#000000"];
    else if (v >= 14 && v < 16) return ["#bdef00", "#000000"];
    else if (v >= 12 && v < 14) return ["#8cde08", "#000000"];
    else if (v >= 10 && v < 12) return ["#5ace08", "#000000"];
    else if (v >= 8 && v < 10) return ["#21bd10", "#000000"];
    else if (v >= 6 && v < 8) return ["#08ce5a", "#000000"];
    else if (v >= 4 && v < 6) return ["#01de94", "#000000"];
    else if (v >= 2 && v < 4) return ["#01efc5", "#000000"];
    else if (v >= 0 && v < 2) return ["#01fff7", "#000000"];
    else if (v > -2 && v < 0) return ["#00ceff", "#000000"];
    else if (v > -4 && v <= -2) return ["#009cff", "#000000"];
    else if (v > -6 && v <= -4) return ["#0063ff", "#000000"];
    else if (v > -8 && v <= -6) return ["#0031ff", "#000000"];
    else if (v > -10 && v <= -8) return ["#2100f7", "#000000"];
    else if (v > -12 && v <= -10) return ["#4a00c5", "#000000"];
    else if (v > -14 && v <= -12) return ["#5a008c", "#000000"];
    else if (v > -16 && v <= -14) return ["#7b319c", "#000000"];
    else return ["#9f5a9c", "#000000"];

}

function humidityColor(val) {
    let v = parseInt(val);

    if (v >= 95) return ["#d400fe", "#000000"];
    else if (v >= 89 && v < 95) return ["#9e00b3", "#000000"];
    else if (v >= 83 && v < 89) return ["#5900b3", "#000000"];
    else if (v >= 77 && v < 83) return ["#005a84", "#000000"];
    else if (v >= 71 && v < 77) return ["#088452", "#000000"];
    else if (v >= 65 && v < 71) return ["#08a521", "#000000"];
    else if (v >= 59 && v < 65) return ["#21b510", "#000000"];
    else if (v >= 53 && v < 59) return ["#52c508", "#000000"];
    else if (v >= 47 && v < 53) return ["#84d608", "#000000"];
    else if (v >= 41 && v < 47) return ["#b5e600", "#000000"];
    else if (v >= 35 && v < 41) return ["#e6f700", "#000000"];
    else if (v >= 29 && v < 35) return ["#ffde00", "#000000"];
    else if (v >= 23 && v < 29) return ["#ffb500", "#000000"];
    else if (v >= 17 && v < 23) return ["#ff8400", "#000000"];
    else if (v >= 10 && v < 17) return ["#ff5200", "#000000"];
    else return ["#ff2100", "#000000"];

}

function precipColor(val) {
    let v = parseFloat(val);

    if (v >= 250) return ["#660033", "#000000"];
    else if (v >= 220 && v < 250) return ["#78083C", "#000000"];
    else if (v >= 190 && v < 220) return ["#8C0A50", "#000000"];
    else if (v >= 160 && v < 190) return ["#A00C64", "#000000"];
    else if (v >= 140 && v < 160) return ["#B40D78", "#000000"];
    else if (v >= 120 && v < 140) return ["#C8128C", "#000000"];
    else if (v >= 100 && v < 120) return ["#E418B1", "#000000"];
    else if (v >= 80 && v < 100) return ["#C01FC0", "#000000"];
    else if (v >= 70 && v < 80) return ["#831BB8", "#000000"];
    else if (v >= 60 && v < 70) return ["#5900B3", "#000000"];
    else if (v >= 50 && v < 60) return ["#1414AA", "#000000"];
    else if (v >= 40 && v < 50) return ["#1E28C8", "#000000"];
    else if (v >= 30 && v < 40) return ["#283CE6", "#000000"];
    else if (v >= 20 && v < 30) return ["#354EF5", "#000000"];
    else if (v >= 15 && v < 20) return ["#3675F4", "#000000"];
    else if (v >= 10 && v < 15) return ["#5192ED", "#000000"];
    else if (v >= 7 && v < 10) return ["#59BAEB", "#000000"];
    else if (v >= 4 && v < 7) return ["#69DAEF", "#000000"];
    else if (v >= 1 && v < 4) return ["#8CF0F0", "#000000"];
    else if (v > 0 && v < 1) return ["#D2FAFA", "#000000"];
    else return ["#FFFFFF", "#000000"];
}

function dewpointColor(val) {
    let v = parseFloat(val);

    if (v >= 27) return ["#a5004a", "#000000"];
    else if (v >= 25 && v < 27) return ["#ce0029", "#000000"];
    else if (v >= 23 && v < 25) return ["#ff1000", "#000000"];
    else if (v >= 21 && v < 23) return ["#ff3a00", "#000000"];
    else if (v >= 19 && v < 21) return ["#ff6300", "#000000"];
    else if (v >= 17 && v < 19) return ["#ff8400", "#000000"];
    else if (v >= 15 && v < 17) return ["#ffa500", "#000000"];
    else if (v >= 13 && v < 15) return ["#ffb500", "#000000"];
    else if (v >= 11 && v < 13) return ["#ffce00", "#000000"];
    else if (v >= 9 && v < 11) return ["#ffef00", "#000000"];
    else if (v >= 7 && v < 9) return ["#efff00", "#000000"];
    else if (v >= 5 && v < 7) return ["#bdef00", "#000000"];
    else if (v >= 3 && v < 5) return ["#8cde08", "#000000"];
    else if (v >= 1 && v < 3) return ["#5ace08", "#000000"];
    else if (v > -1 && v < 1) return ["#08ce5a", "#000000"];
    else if (v > -3 && v <= -1) return ["#01de94", "#000000"];
    else if (v > -5 && v <= -3) return ["#01efc5", "#000000"];
    else if (v > -7 && v <= -5) return ["#00ceff", "#000000"];
    else if (v > -9 && v <= -7) return ["#009cff", "#000000"];
    else if (v > -11 && v <= -9) return ["#0063ff", "#000000"];
    else if (v > -13 && v <= -11) return ["#0031ff", "#000000"];
    else if (v > -15 && v <= -13) return ["#4a00c5", "#000000"];
    else if (v > -17 && v <= -15) return ["#5a008c", "#000000"];
    else if (v > -19 && v <= -17) return ["#7b319c", "#000000"];
    else return ["#9f5a9c", "#000000"];
}

function escursioneColor(val) {
    let v = parseFloat(val);

    if (v >= 24) return ["#ce0029", "#000000"];
    else if (v >= 21 && v < 24) return ["#ef0008", "#000000"];
    else if (v >= 18 && v < 21) return ["#ff3a00", "#000000"];
    else if (v >= 15 && v < 18) return ["#ff8400", "#000000"];
    else if (v >= 12.5 && v < 15) return ["#ffb500", "#000000"];
    else if (v >= 10 && v < 12.5) return ["#ffde00", "#000000"];
    else if (v >= 7.5 && v < 10) return ["#efff00", "#000000"];
    else if (v >= 5 && v < 7.5) return ["#8cde08", "#000000"];
    else if (v >= 2.5 && v < 5) return ["#21bd10", "#000000"];
    else return ["#08ce5a", "#000000"];
}

function pressureColor(pressione) {
    let v = parseInt(pressione);

    if (v >= 1045) return ["#aa0000", "#000000"];
    else if (v >= 1040 && v < 1045) return ["#d30000", "#000000"];
    else if (v >= 1035 && v < 1040) return ["#fa5000", "#000000"];
    else if (v >= 1030 && v < 1035) return ["#fe7200", "#000000"];
    else if (v >= 1025 && v < 1030) return ["#fe7200", "#000000"];
    else if (v >= 1020 && v < 1025) return ["#fed200", "#000000"];
    else if (v >= 1015 && v < 1020) return ["#e1ff00", "#000000"];
    else if (v >= 1010 && v < 1015) return ["#bdf23d", "#000000"];
    else if (v >= 1005 && v < 1010) return ["#bdf23d", "#000000"];
    else if (v >= 1000 && v < 1005) return ["#01e9fe", "#000000"];
    else if (v >= 995 && v < 1000) return ["#00bafe", "#000000"];
    else if (v >= 990 && v < 995) return ["#0080ff", "#000000"];
    else if (v >= 985 && v < 990) return ["#8000ff", "#000000"];
    else if (v >= 980 && v < 985) return ["#7b319c", "#000000"];
    else return ["#9f5a9c", "#000000"];

}

function windSpeed(val) {
    let v = parseInt(val);

    if (v >= 152) return ["#fa99ff", "#000000"];
    else if (v >= 135 && v < 152) return ["#ff65b9", "#000000"];
    else if (v >= 119 && v < 135) return ["#db0099", "#000000"];
    else if (v >= 104 && v < 119) return ["#a5004a", "#000000"];
    else if (v >= 90 && v < 104) return ["#ce0029", "#000000"];
    else if (v >= 77 && v < 90) return ["#ff1000", "#000000"];
    else if (v >= 65 && v < 77) return ["#ff3a00", "#000000"];
    else if (v >= 54 && v < 65) return ["#ff6300", "#000000"];
    else if (v >= 44 && v < 54) return ["#ff8400", "#000000"];
    else if (v >= 35 && v < 44) return ["#ffb500", "#000000"];
    else if (v >= 27 && v < 35) return ["#ffde00", "#000000"];
    else if (v >= 20 && v < 27) return ["#efff00", "#000000"];
    else if (v >= 14 && v < 20) return ["#bdef00", "#000000"];
    else if (v >= 9 && v < 14) return ["#8cde08", "#000000"];
    else if (v >= 5 && v < 9) return ["#5ace08", "#000000"];
    else if (v >= 2 && v < 5) return ["#42f88c", "#000000"];
    else return ["#8efad3", "#000000"];
}