import React from 'react'

import "./ErrorBanner.css";

function ErrorBanner({text}) {
    return (
        <div className='error-container'>
            <img src={'/img/logo_errore.png'} alt="error banner"/>
            <p><b>ERRORE!&nbsp;</b>{text}</p>
        </div>
    )
}

export default ErrorBanner