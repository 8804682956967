import React,{useEffect, useState} from 'react'
import ReactGA from 'react-ga4'
import ReactPlayer from 'react-player'
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom'
import { Crypto } from '../scripts/crypto';

import "./WebcamPage.css";
import { Button } from './Button';
import ReactImageGallery from 'react-image-gallery';
import Requests from '../scripts/Requests';

function WebcamPage() {

    const history = useHistory();
    let {id} = useParams();

    const [webcamID, setWebcamID] = useState(id);
    const [webcamAPI, setWebcamAPI] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [gallery, setGallery] = useState(null);    
    const [nearbyStations, setNearbyStations] = useState([]);

    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Webcam page" });

        Requests.get_webcam_by_id(id)
        .then(webcam => {
            //console.log(webcam);
            if("img" in webcam){
                if(webcam.img !== null && webcam.img.length > 0){
                    //console.log(webcam.img);
                    let imgArray = [];
                    webcam.img.forEach(el =>{
                        imgArray.push({"original": el, "thumbnail": el});
                    });
                    setGallery(imgArray);
                }
            }
            if("nearbyStations" in webcam){
                if(webcam.nearbyStations){
                    setNearbyStations(webcam.nearbyStations);
                }
            }
            setWebcamAPI(webcam);
            setDataLoaded(true);
        })
        /*
        .then(webcams => {
            //let data = JSON.parse(Crypto.decode(webcams));
            let webcam = webcams.filter((item) => item._id === webcamID)[0];
            if("img" in webcam){
                if(webcam.img !== null && webcam.img.length > 0){
                    //console.log(webcam.img);
                    let imgArray = [];
                    webcam.img.forEach(el =>{
                        imgArray.push({"original": el, "thumbnail": el});
                    });
                    setGallery(imgArray);
                }
            }
            if("nearbyStations" in webcam){
                //console.log(webcam.nearbyStations);
                setNearbyStations(webcam.nearbyStations);
            }
            setWebcamAPI(webcam);
            setDataLoaded(true);
        })
        */
        .catch(err => console.log(err));
        
    },[])

    return dataLoaded && (
        <div className="webcam-page-wrapper">
            
            {webcamAPI &&
            <>
            <div className="webcam-page-commands">
                <Button buttonStyle="btn--primary" onClick={() => history.goBack()} noLink>
                    INDIETRO
                </Button>
                <span className="page-title">
                    <p>WEBCAM DI </p>
                    <h2 className="station-title">
                        {webcamAPI.title}
                    </h2>
                </span>
                
            </div>
            <div className="webcam-container">
                
                {
                    "url" in webcamAPI && 
                    <img src={
                        webcamAPI.url.startsWith("http://")
                        ?
                        process.env.REACT_APP_BACKEND + "/api/img?url=" + webcamAPI.url
                        :
                        webcamAPI.url
                    } />
                    //:
                    //<ReactPlayer url={webcamAPI.url}/>
                }
                <div className="webcam-description">
                    <div>
                        <h4>DESCRIZIONE</h4>
                        <p>{webcamAPI.description}</p>
                    </div>
                    {webcamAPI.website && <p>SITO WEB: <a href={webcamAPI.website} target="_blank">{webcamAPI.website}</a></p>}
                </div>
            </div>
            
            {nearbyStations.length > 0 && <hr className='separator'/>}
            {nearbyStations.length > 0 &&
                <div className='webcam-banner-container'>
                    <h2>STAZIONI VICINE</h2>
                    <div className='webcam-banner-row'> 
                    {
                        nearbyStations.map( stationsID => {
                            return (
                            <a href={"/station/"+stationsID}>
                                <img src={process.env.REACT_APP_BACKEND +"/banner/"+stationsID} alt="cwd_banner"/>
                            </a>
                            )
                        })
                    }
                    </div>
                </div>
            }

            {gallery && <hr className='separator'/>}
            
            { gallery &&
                <div className='webcam-slider-container'>
                    <h2 style={{textAlign: "center"}}>Galleria immagini</h2>
                    <ReactImageGallery items={gallery} showPlayButton={false} />
                </div>
            }
            </>
            }
        </div>
    )
}

export default WebcamPage
