import React, { useState } from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--black','btn--outline', 'btn--test', "btn--cyan", "btn--outline-cyan","btn--search", "btn--outline-search","no--style"];
const SIZES = ['btn--small','btn--medium', 'btn--large'];
const COLOR = ['white'];

export const Button = ({
  id,
  children,
  path,
  type,
  disableHover,
  onClick,
  onTouchStart,
  buttonStyle,
  buttonSize,
  fullWidth,
  fullHeight,
  noRounded,
  color,
  className,
  noLink,
  style,
  disabled
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[STYLES.length-1];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[1];
  const checkWidth = fullWidth ? "full-width" : "";
  const checkHeight = fullHeight ? "full-height" : "";
  const rounded = noRounded ? "no-rounded" : ""; 
  const checkColor = COLOR.includes(color) ? color : ""; 
  //const hoverStyle = disableHover ? "no--hover" : ""; 
  var enableHover = isHover ? checkButtonStyle+"-hover":"";

  const [isHover, setIsHover] = useState(false)

  return noLink ? (
      <button
        id={id}
        style={style}
        className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkWidth} ${checkHeight} ${rounded} ${checkColor} ${isHover && !disableHover ? checkButtonStyle+"-hover":""}`}
        onClick={onClick}
        onTouchStart={onTouchStart}
        type={type}
        onMouseEnter={()=>setIsHover(p => !p)}
        onMouseLeave={()=>setIsHover(p => !p)}
        //disabled={disabled}
      >
        {children}
      </button>
    )
    :  
    (
    <Link className={className ? className : ""} to={(path ? path : "#")}>
      <button
        id={id}
        className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkWidth} ${checkHeight} ${rounded} ${checkColor} ${isHover ? checkButtonStyle+"-hover":""}`}
        onClick={onClick}
        onTouchStartCapture={onTouchStart}
        type={type}
        onMouseEnter={()=>setIsHover(p => !p)}
        onMouseLeave={()=>setIsHover(p => !p)}
        //disabled={disabled}
      >
        {children}
      </button>
    </Link>
  );
};