import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'



import "./Nivometria.css";
import description from '../../data/nivometria-description.json'
import { Point } from 'leaflet';
import NivometriaChart from '../../components/NivometriaChart';
import ErrorBanner from '../../components/ErrorBanner';
import { CircularProgress } from '@mui/material';
import Requests from '../../scripts/Requests';

function Nivometria() {

    const [json, setJson] = useState(null);
    const [loading, setLoading] = useState(true);
    const [connectionError, setConnectionError] = useState(false);


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Nivometria" });
        
        Requests.get_snow()
        .then(res => {
            setJson(res)
            setLoading(false)
        })
        .catch(err => {
            if(!connectionError) setConnectionError(prev => !prev);
        });
        
        return () => { }
    }, []);

    return (
        <div className="nivometria-wrapper">
            {
                !loading ? (
                    <>
                        <div className="nivometria-title">
                            <h2>Nivometria</h2>
                        </div>
                        {json &&
                            <ul className="nivometria-list">
                                {
                                    json.map(e =>
                                        <li key={e.id}>
                                            <NivometriaElement data={e} />
                                            <hr style={{ color: "var(--primary)" }}></hr>
                                        </li>
                                    )
                                }
                            </ul>
                        }
                    </>
                ) : (
                    connectionError ?
                        <ErrorBanner text={"Riprova più tardi..."} />
                        :
                        <div className={"progress-wrapper"}><CircularProgress size={18} /></div>
                    )
                }
        </div>
    )
}



function NivometriaElement(props) {
    //const satellite = "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
    const satellite = "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2FsYWJyaWF3ZWF0aGVyZGF0YSIsImEiOiJja2ZvMHJiYmQwM21jMnhwYTV4ejc1ZXczIn0.n1KoSj8nlFJMn98uyWilhA"

    const [desc, setDesc] = useState();

    useEffect(()=>{
        let text = description.filter(e => e.id === props.data.id)
        if(text.length > 0){
            setDesc(text[0].description)
        }else{
            setDesc("")
        }
        //setDesc(text)
        //console.log(text);
    },[])

    return (
        <div id={props.data.id} className="nivometria-element" >
            <MapContainer
                center={[props.data.lat, props.data.lng]}
                zoom={8}
                minZoom={8}
                maxZoom={15}
                scrollWheelZoom={true}
                zoomControl={false}
                dragging={false}
                style={{ width: "300px", height: "300px", zIndex: -1 }}
            >
                <TileLayer url={satellite} />
                <Marker position={[props.data.lat, props.data.lng]}>
                    <Tooltip direction="top" offset={new Point(0, -12)}>{props.data.locality}</Tooltip>
                </Marker>
            </MapContainer>
            <div className="nivometria-element-data">
                <h3>{props.data.locality} ({props.data.province.toUpperCase()})</h3>
                <p className="nivometria-element-desc">{desc}</p>
                <ul className="nivometria-element-values">
                    <li id="nivometria-date">
                        <span><b>DATA</b></span>
                        <p>{props.data.lastDate ? props.data.lastDate : "-"}</p>
                    </li>
                    <li id="nivometria-temp-min">
                        <span><b>TEMP MIN</b></span>
                        <p>{parseInt(props.data.tempMin) ? props.data.tempMin + "°" : "-"}</p>
                    </li>
                    <li id="nivometria-temp-max">
                        <span><b>TEMP MAX</b></span>
                        <p>{parseInt(props.data.tempMax) ? props.data.tempMax + "°" : "-"}</p>
                    </li>
                    <li id="nivometria-last-hours">
                        <span><b>ACCUMULO ULTIME 24H</b></span>
                        <p>{props.data.accumulation24 + " cm"}</p>
                    </li>
                    <li id="nivometria-snow-height">
                        <span><b>ALTEZZA MANTO NEVOSO</b></span>
                        <p>{props.data.snowHeight + " cm"}</p>
                    </li>
                </ul>

                <div className="nivometria-element-chart">
                    <NivometriaChart stationId={props.data.id} />
                </div>
            </div>

        </div>
    )
}


export default Nivometria
