import React from 'react'

import "./Informativa.css"

function Informativa() {
    return (
        <div className="informativa-wrapper">
            <h2 id="informativa-title">Altre informative</h2>
            <div className="disclaimer">
                <h3>Disclaimer</h3>
                <ul>
                    <li>
                        <p>
                        “Calabria Weather Data” è un Sito Web di natura amatoriale, 
                        senza alcuna finalità commerciale diretta o indiretta, 
                        non riconducibile ad alcuna attività economica od organizzazione avente medesime finalità, 
                        senza introiti o finanziamenti di alcuna natura, progettato e realizzato 
                        esclusivamente per la condivisione sul Web dei dati delle stazioni meteorologiche 
                        dei proprietari del sito, eventualmente supportato da articoli di approfondimento 
                        di tematiche inerenti la meteorologia, con assoluto carattere di discontinuità e casualità.
                        </p>
                    </li>
                    <li>
                        <p>
                        I dati delle stazioni meteo, i widgets e qualsiasi altro contenuto o strumento multimediale 
                        rivestono carattere di non ufficialità, ancorché forniti da organizzazioni esterne.
                        </p>
                    </li>
                    <li>
                        <p>
                        La riproduzione di Webcam, nel rispetto delle Norme Legislative in vigore, 
                        è conforme al Provv. del Garante per la Protezione dei Dati Personali del 08/04/10, 
                        pubblicato in G.U. n. 99 del 29/04/10. Non permette l’identificazione dei soggetti ripresi, 
                        nè la lettura delle targhe automobilistiche. Le riprese non interessano proprietà private 
                        internamente visibili. Le immagini saranno visibili in Internet per scopi meteorologici e 
                        promozionali-turistici.
                        </p>
                    </li>
                    <li>
                        <p>
                        Il sito è ottimizzato per i seguenti browsers: Google Chrome, Mozilla Firefox, Safari, Microsoft Edge e altri.
                        </p>
                    </li>
                    <li>
                        <p>
                        Ai sensi della Legge Italiana n. 62/2001, il Sito Web “Calabria Weather Data” non può essere 
                        considerato una “testata giornalistica”, né un mezzo di informazione o un prodotto editoriale,
                         in quanto non viene aggiornato con cadenza periodica.
                        </p>
                    </li>
                    <li>
                        <p>
                        I Proprietari del Sito Web “Calabria Weather Data”  non si assumono alcuna responsabilità 
                        per conseguenze dirette o indirette derivanti dalla consultazione del medesimo e non 
                        rilascia alcun tipo di garanzia sul contenuto dei messaggi e/o comunicazioni pubblicati 
                        in qualsiasi forma.
                        </p>
                    </li>
                    <li>
                        <p>
                        I Proprietari del Sito Web “Calabria Weather Data” non sono responsabili dei collegamenti 
                        e links a siti esterni e, pertanto, non offrono alcuna garanzia e non formulano alcuna 
                        dichiarazione in relazione ai loro contenuti od eventuali risultati ottenuti dal loro uso.
                        </p>
                    </li>
                    <li>
                        <p>
                        Si sottolinea espressamente che il mancato esercizio da parte del proprietario del Sito Web
                         “Calabria Weather Data” di qualsiasi diritto derivante dal presente Disclaimer 
                         non costituisce in nessun caso rinuncia al diritto medesimo.
                        </p>
                    </li>
                    <li>
                        <p>
                        Nonostante tutti i contenuti presenti siano stati autorizzati dai rispettivi proprietari 
                        o detentori dei diritti, o di dominio pubblico, o concessi con licenze specifiche 
                        rigorosamente rispettate, eventuali ed involontarie violazioni di diritti d’autore, 
                        richieste di modifiche e rimozione, precisazioni, richieste di replica o rettifica 
                        dei commenti dei lettori, vanno segnalate ed inoltrate inviando una email 
                        a calabriaweatherdata@gmail.it
                        </p>
                    </li>
                    <li>
                        <p>
                        I dati personali, gli indirizzi e-mail e tutte le altre informazioni raccolte dal sistema, 
                        commenti, contribuzioni e altro, saranno trattati in accordo con le legislazioni degli 
                        Stati di appartenenza degli Hosting Providers e, specificamente per l’Italia, 
                        in conformità alle disposizioni del Decreto Legislativo 30 Giugno 2003, n. 196 
                        (cd. Codice in materia di protezione dei dati personali o Testo Unico Privacy) 
                        e successive modificazioni ed integrazioni dell’Autorità Garante.
                        </p>
                    </li>
                    <li>
                        <p>
                        Eventuali links pubblicitari e/o formule di advertising e di marketing sono gestiti 
                        unilateralmente da Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, 
                        United States, tramite il Servizio Adsense. Il proprietario del Sito non si ritiene 
                        responsabile per il loro utilizzo e per il relativo messaggio o contenuto multimediale.
                        </p>
                    </li>
                </ul>
            </div>
            { // DA FINIRE
            /*
            <div className="condizioni-di-utilizzo">
                <h3>Condizioni di utilizzo</h3>
                <p>L'utente che decide di aderire alla rete meteorologica "Calabria Weather Data" 
                    consente l'utilizzo dei propri dati (immessi durante la fase di adesione) per 
                    effettuare le seguenti azioni:<br>
                </br>
                </p>
            </div>
            */
            }
        </div>
    )
}

export default Informativa
