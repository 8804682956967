export function translateMonths(label) {
    let month = label.match(/Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Nov|Dec/g);

    if (!month)
        return label;

    let translation = month_enEN_itIT(month[0]);
    return label.replace(month, translation, 'g');
}

export function convertTimestampToDate(timestamp) {
    const dateObject = new Date(timestamp);
    return dateObject;
}

export function getTimeInHHMM(dateObject) {
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}

function month_enEN_itIT(month) {
    var result = month;

    switch (month) {
        case 'Jan':
            result = 'Gen';
            break;

        case 'May':
            result = 'Mag';
            break;

        case 'Jun':
            result = 'Giu';
            break;

        case 'Jul':
            result = 'Lug';
            break;

        case 'Aug':
            result = 'Ago';
            break;
        case 'Sep':
            result = 'Set';
            break;

        case 'Oct':
            result = 'Ott';
            break;

        case 'Dec':
            result = 'Dic';
            break;

    }

    return result;
}