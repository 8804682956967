import React, { useEffect, useState, useRef, useMemo, memo } from 'react'
import { MapContainer, Marker, Popup, GeoJSON, Tooltip } from 'react-leaflet'
import { CircularProgress } from '@mui/material'
import ReactGA from 'react-ga4'
import { switchColors, switchFontColors } from '../scripts/functions'
import PopupBox from '../components/PopupBox';
import $ from 'jquery'
import ErrorBanner from '../components/ErrorBanner';
import Requests from '../scripts/Requests';


import L, { Point } from 'leaflet';
import 'leaflet-easybutton';
import 'leaflet-timedimension'

import "leaflet-timedimension/src/leaflet.timedimension.control.css"
import "./Map.css";

const svgWindMarker = (color, border, idx) => {
    return `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
<style type="text/css">
	#st`+ idx + `{fill:` + color + `;stroke:` + border + `;stroke-miterlimit:10;}
</style>
<g id="Livello_2">
	<path id="st`+ idx + `" d="M24.9,2l-7.4,20.9c-1.1,3.1-0.3,6.7,2.3,8.8c0.2,0.2,0.4,0.3,0.6,0.4c2.8,1.9,6.5,1.9,9.3,0
		c0.2-0.1,0.4-0.3,0.6-0.4c2.6-2.1,3.4-5.7,2.3-8.9L24.9,2z"/>
</g>
</svg>
`};

//const mbUrl = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
const satellite = "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2FsYWJyaWF3ZWF0aGVyZGF0YSIsImEiOiJja2ZvMHJiYmQwM21jMnhwYTV4ejc1ZXczIn0.n1KoSj8nlFJMn98uyWilhA"
//const satellite = "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.webp?sku=101Fm4yPG80cp&access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NDg1bDA1cjYzM280NHJ5NzlvNDMifQ.d6e-nNyBDtmQCVwVNivz7A"
const topografica = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}";


const protezione_civile_wms = "https://radar-geowebcache.protezionecivile.it/service/wms"
const attribute = 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';

function Map() {

    //MAP
    const [map, setMap] = useState(null);

    //STATE
    const [mapControls, setMapControls] = useState(false);
    const [type, setType] = useState("temp");
    const [stations, setStations] = useState([]);
    const [snowData, setSnowData] = useState([]);
    const [reports, setReports] = useState([]);
    const [webcamData, setWebcamData] = useState([]);
    const [updateInterval, setUpdateInterval] = useState(null);
    const [overlay, setOverlay] = useState(false);
    const [webcam, setWebcam] = useState({});

    //MARKERS VISIBILITY
    const [showSnow, setShowSnow] = useState(false);
    const [showWebcam, setShowWebcam] = useState(false);
    const [showReports, setShowReports] = useState(false);
    //const [showStations, setShowStations] = useState(true);

    //GEOJSON
    const [calabria, setCalabria] = useState(null);
    const [calabriaLabel, setCalabriaLabel] = useState(null);

    //DATA LOADED
    const [dataLoaded, setDataLoaded] = useState(false);
    const [connectionError, setConnectionError] = useState(false);

    async function addGeoJson() {
        var response = await fetch("../../geojson/calabria_province.geojson");
        var data = await response.json();
        setCalabria(data);

        response = await fetch("../../geojson/calabria_province_labels.geojson");
        data = await response.json();
        setCalabriaLabel(data);
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Mappa" });

        if (window.innerWidth < 480)
            $("#map").height(window.innerHeight - 65);


        async function getData() {
            //await getStations();
            //await getSnowData();
            //await getWebcamData();
            await Requests.get_snow()
                .then(res => {
                    setSnowData(res);
                })
                .catch(err => console.log(err))

            await Requests.get_webcams()
                .then(res => {
                    setWebcamData(res);
                })
                .catch(err => console.log(err))

            await Requests.get_stations_live()
                .then(res => {
                    setStations(res);
                })

            Requests.get_all_active_reports()
                .then(res => {
                    setReports(res);
                    console.log(res);
                })
                .catch(err => {
                    console.error(err);
                })
        }

        addGeoJson();

        getData()
            .then(() => {
                setDataLoaded(true);
            })
            .catch(err => {
                if (!connectionError) setConnectionError(prev => !prev);
            });




        return () => { }
    }, []);

    useEffect(() => {

        $(".navbar").css("position", "relative");

        let interval = setInterval(() => {
            Requests.get_stations_live()
                .then(res => setStations(res))
                .catch(err => console.log(err));
            console.log("[STATIONS] Check update!");

            Requests.get_all_active_reports()
                .then(res => setReports(res))
                .catch(err => console.log(err));
            console.log("[REPORTS] Check update!");
        }, 1000 * 60);
        if (!updateInterval) {
            setUpdateInterval(interval);
        }

        return () => {
            $(".navbar").css("position", "fixed");
            clearInterval(updateInterval);
        }
    }, [])

    useEffect(() => {
        if (connectionError) {
            $("#map").css({
                "opacity": 0.3
            });
            $('.leaflet-control-container').hide();

            map.dragging.disable();
            map.touchZoom.disable();
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();

            $(".error-container").css({
                "position": "absolute",
                "top": "120px",
                "width": "100%",
                "background-color": "#fff",
                "opacity": "1"
            });
        }
    }, [connectionError])

    //MAP LAYERS
    useEffect(() => {
        if (map && !mapControls) {
            const timeDimension = new L.TimeDimension({
                period: "PT5M", // Intervallo di 3 ore
                currentTime: new Date(), // Tempo corrente

                // Opzioni di animazione e avanzamento temporale
                autoPlay: false,        // Avvia l'animazione automaticamente
                timeSlider: false,      // Visualizza uno slider temporale
                timeSliderDragUpdate: false, // Aggiorna il tempo durante il trascinamento dello slider
                loopButton: false,      // Mostra il pulsante di ripetizione
                playerOptions: {
                    buffer: 10,          // Buffer di anticipazione (secondi)
                    minBufferReady: -1,  // Anticipazione minima pronta (secondi)
                    playReverseButton: false, // Pulsante di riproduzione inversa
                },

                requestTimeCallback: function (time, request) {
                    const formattedTime = time.toISOString(); // Formatta il tempo in formato ISO 8601
                    request.params.TIME = formattedTime; // Aggiungi il parametro TIME alla richiesta WMS
                    return request;
                },

            });
            // Imposta manualmente la sequenza con scatti di 5 minuti
            const startTime = new Date();
            //const startTime = new Date('2023-09-16');

            startTime.setMinutes(Math.ceil(startTime.getMinutes() / 5) * 5);
            startTime.setSeconds(0);
            startTime.setMilliseconds(0);

            startTime.setHours(startTime.getHours() - 3)
            startTime.setMinutes(startTime.getMinutes() - 20)
            const endTime = new Date();
            endTime.setMinutes(endTime.getMinutes() - 20)
            const timeSequence = [startTime];

            let currentTime = startTime;

            while (currentTime < endTime) {
                timeSequence.push(new Date(currentTime));
                currentTime.setMinutes(currentTime.getMinutes() + 5);
            }

            timeDimension.setAvailableTimes(timeSequence);
            map.timeDimension = timeDimension;

            var layer_sat = L.tileLayer(satellite, { id: 'sat', tileSize: 512, zoomOffset: -1, attribution: attribute });
            var layer_topo = L.tileLayer(topografica, { id: 'topo', tileSize: 512, zoomOffset: -1, attribution: attribute });


            var wmsLayer = L.tileLayer.wms(protezione_civile_wms, {
                layers: 'radar:sri',
                format: "image/png",
                transparent: true,
                attribution: "Dipartimento della Protezione Civile",
                timeDimension: timeDimension,
                updateTimeDimension: true,
                getCapabilitiesUrl: protezione_civile_wms + "?SERVICE=WMS&VERSION=1.1.1&REQUEST=getcapabilities&TILED=true"
            });

            const radarTimeLayer = L.timeDimension.layer.wms(wmsLayer, {
                updateTimeDimension: true,
                cacheBackward: true,
            });

            var baseMaps = {
                "Satellite": layer_sat,
                "Topografica": layer_topo,
            };

            var overlayMaps = {
                "Radar Meteo": radarTimeLayer
            };


            layer_sat.addTo(map);

            var layersControl = L.control.layers(baseMaps, overlayMaps, {}).setPosition('topleft').addTo(map);
            //setLayersControl(layersControl)

            /*
            var radarLegend = L.control({
                position: 'bottomright'
            });
            radarLegend.onAdd = function (map) {
                var div = L.DomUtil.create('div', 'info legend');
                div.innerHTML += '<h1>RADAR METEO</h1>';
                return div;
            };
            */


            const timeDimensionControl = new L.Control.TimeDimension({
                timeDimension: timeDimension,
                position: 'bottomright'
            });

            map.on('overlayadd', function (eventLayer) {
                if (eventLayer.name == 'Radar Meteo') {
                    //radarLegend.addTo(this);
                    map.addControl(timeDimensionControl);
                }
            });

            map.on('overlayremove', function (eventLayer) {
                if (eventLayer.name == 'Radar Meteo') {
                    map.removeControl(timeDimensionControl);
                }
            });

        }
    }, [map, mapControls]);

    //MAP BUTTONS
    useEffect(() => {
        if (map && !mapControls) {
            L.easyBar([
                L.easyButton('fa-thermometer-half control-button', (btn) => {
                    setType("temp");
                }, "temp"),
                L.easyButton("fa-thermometer-empty control-button temp-min", () => { setType("tempLow") }, "temp-min"),
                L.easyButton("fa-thermometer-three-quarters control-button temp-max", () => { setType("tempHigh") }, "temp-max"),
                L.easyButton("fa-chart-line control-button excursion", () => { setType("excursion") }, "escursione"),
            ], { position: 'topright' }).addTo(map);


            L.easyBar([
                L.easyButton('fas fa-cloud-rain control-button', () => { setType("precipTotal") }, "pioggia"),
            ], { position: 'topright' }).addTo(map);

            L.easyBar([
                L.easyButton('fa-tint humidity control-button', () => { setType("humidity") }, "umidità"),
            ], { position: 'topright' }).addTo(map);

            L.easyBar([
                L.easyButton('fa-flag control-button', () => { setType("windSpeed") }, "vento"),
                L.easyButton('fa-wind control-button', () => { setType("windGustHigh") }, "raffica"),
            ], { position: 'topright' }).addTo(map);

            L.easyBar([
                L.easyButton('fa-tachometer-alt control-button', () => { setType("pressure") }, "pressione"),
            ], { position: 'topright' }).addTo(map);

            L.easyBar([

                L.easyButton({
                    states: [
                        {
                            stateName: 'snow-disabled',
                            icon: 'fa-snowflake control-button snowflake-color',
                            title: 'show snow',
                            onClick: function (control) {
                                control.state('snow-active');
                                setShowSnow(prev => !prev);
                            }
                        },
                        {
                            stateName: 'snow-active',
                            icon: 'fa-snowflake control-button snowflake-color snowflake-button-checked',
                            title: 'show snow',
                            onClick: function (control) {
                                control.state('snow-disabled');
                                setShowSnow(prev => !prev);
                            }
                        },

                    ]
                }),
                L.easyButton({
                    states: [
                        {
                            stateName: 'webcam-disabled',
                            icon: 'fa-solid fa-video control-button',
                            title: 'show webcam',
                            onClick: function (control) {
                                control.state('webcam-active');
                                setShowWebcam(prev => !prev);
                            }
                        },
                        {
                            stateName: 'webcam-active',
                            icon: 'fa-solid fa-video control-button snowflake-button-checked',
                            title: 'show webcam',
                            onClick: function (control) {
                                control.state('webcam-disabled');
                                setShowWebcam(prev => !prev);
                            }
                        }
                    ]
                }),

                L.easyButton({
                    states: [
                        {
                            stateName: 'reports-disabled',
                            icon: 'fa-solid fa-bell control-button ',
                            title: 'show reports',
                            onClick: function (control) {
                                control.state('reports-active');
                                setShowReports(prev => !prev);
                            }
                        },
                        {
                            stateName: 'reports-active',
                            icon: 'fa-solid fa-bell control-button snowflake-button-checked',
                            title: 'show reports',
                            onClick: function (control) {
                                control.state('reports-disabled');
                                setShowReports(prev => !prev);
                            }
                        }
                    ]
                })
            ], { position: 'topright' }).addTo(map);

            setMapControls(true);
        }
    }, [map]);

    function parseUploadDate(startDate) {
        var now = new Date(); // Ottiene la data attuale
        var startDateObj = new Date(startDate);

        // Calcola la differenza in millisecondi tra le due date
        var timeDifference = now - startDateObj;

        // Converti la differenza in ore, facendo attenzione ai millisecondi in un'ora
        var hoursDifference = Math.abs(timeDifference / (1000 * 60 * 60));

        return parseInt(hoursDifference);
    }


    function hasRecentReports(timestamps) {
        const now = new Date();
        now.setHours(now.getHours() - 1);

        for (const timestampString of timestamps) {
            const timestamp = new Date(timestampString);
            if (!isNaN(timestamp) && timestamp > now) {
                return true;
            }
        }

        return false;
    }

    const CustomMarker = ({ data, type, markerID }) => {

        //console.log(data);
        var icon;
        var marker = useRef();

        const ReportImageComp = memo(({ popupData }) => {
            const [images, setImages] = useState(Array(popupData['ids'].length).fill(null))
            const [reportIdx, setReportIdx] = useState(0)

            useEffect(() => {
                if (!images[reportIdx]) {
                    Requests.get_report_by_id(popupData['ids'][reportIdx])
                        .then(res => {
                            if(res){
                                let newImages = [...images]
                                newImages[reportIdx] = res['img']
                                setImages(newImages)
                            }else{
                                let newImages = [...images]
                                newImages[reportIdx] = -1
                                setImages(newImages)
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            let newImages = [...images]
                            newImages[reportIdx] = -1
                            setImages(newImages)
                        })
                }
            }, [reportIdx])

            return (
                <div className='report-popup'>
                    {images[reportIdx] !== -1 && images[reportIdx] && <p className='report-popup-date'>{parseUploadDate(popupData['upload_dates'][reportIdx])} ore fa</p>}
                    <h3 className='report-popup-city'>{popupData["cities"][reportIdx]}</h3>
                    {popupData["localities"][reportIdx] !== "" && <h4 className='report-popup-locality'>{popupData["localities"][reportIdx]}</h4>}
                    <>
                        {images[reportIdx] ?
                            images[reportIdx] !== -1 ?
                                <img src={"data:image/jpg;base64," + images[reportIdx]} />
                                :
                                <p style={{color: 'red'}}>Impossibile caricare l'immagine</p>
                            :
                            <CircularProgress ></CircularProgress>
                        }
                        {
                            popupData['ids'].length > 1 && reportIdx > 0 &&
                            <a className='report-prev-btn' onClick={(e) => {
                                e.stopPropagation()
                                setReportIdx(prev => prev - 1)
                            }}><i className='fa fa-chevron-left'></i></a>

                        }
                        {
                            popupData['ids'].length > 1 && reportIdx < popupData['ids'].length - 1 &&
                            <a className='report-next-btn' onClick={(e) => {
                                e.stopPropagation()
                                setReportIdx(prev => prev + 1)
                            }}><i className='fa fa-chevron-right'></i></a>

                        }
                    </>
                    <div className='report-popup-metadata'>
                        <div className='report-dots'>{images.map((el, idx) => {
                            return (
                                <a key={idx} onClick={() => setReportIdx(idx)}> {idx !== reportIdx ? "○" : "●"}</a>
                            )
                        })}</div>
                        {popupData["authors"][reportIdx] !== "" && <p>{"Autore: " + popupData["authors"][reportIdx]}</p>}
                    </div>
                </div>
            )
        })

        var color = switchColors(type, data[type]);
        var colorBorder = switchColors(type, data[type], 1);

        if (type === "pressure") {
            icon = L.divIcon({ html: data[type], className: "rectangle-marker" });
        } else if (type === "webcam") {
            icon = L.divIcon({ html: '<i class="fa-solid fa-video"></i>', className: "webcam-marker", iconSize: 24 });
        } else if (type === "snowHeight") {
            icon = L.divIcon({ html: '<i class="fas fa-snowflake snow-marker-icon"></i>', className: "snow-marker", iconSize: 36 });
        } else if (type === "windSpeed") {
            icon = L.divIcon({ html: `<div class="wind-dir-container">` + svgWindMarker(color, colorBorder, markerID) + `<p>` + data[type] + `</p></div>`, className: "wind-marker-wrapper", iconSize: 56 });
        } else if (type === "humidity") {
            icon = L.divIcon({ html: parseInt(data[type]), className: "circle-marker", iconSize: 24 });
        } else if (type === "reports") {
            var new_reports = hasRecentReports(data['upload_dates']) 
            let new_marker = new_reports  ? '<i class="fa fa-star new-report-marker"></i>' : ""
            let reports_number = data['cities'].length > 0 ? '<p class="multiple-reports">' + data['cities'].length + "</p>" : ""
            icon = L.divIcon({ html: `<div class="report-marker-container"><i class="fa fa-bell"></i>` + new_marker + reports_number + `</div>`, className: "report-marker", iconSize: 24 });
        } else {
            icon = L.divIcon({ html: data[type], className: "circle-marker", iconSize: 24 });
        }


        function markerRotation(value) {
            let v = parseInt(value);
            if (v < 180) return v + 180;
            return (v + 180) - 360;
        }

        useEffect(() => {
            //console.log(data);
            var overlayData = ["windSpeed", "webcam", "reports"]
            if (marker.current && !overlayData.includes(type)) {
                marker.current._icon.style.color = switchFontColors(type, data[type]);
                marker.current._icon.style.fontFamily = "FuturaND";
                marker.current._icon.style.fontWeight = "Normal";
                marker.current._icon.style.backgroundColor = color;
                marker.current._icon.style.border = "1px solid" + colorBorder;
            } else if (type === "windSpeed" && marker.current !== undefined) {
                marker.current._icon.children[0].children[0].style.transform = "rotate(" + markerRotation(data["windDir"]) + "deg)";
                //marker.current._icon.children[0].style.transform = "rotate("+data["windDir"]+"deg)";
                //marker.current._icon.children[0].style.width = "96px";
            }

        }, [])

        /*
        useEffect(() => {

            if (!countdown && data && !countdownEl) {
                let intv = setInterval(() => {
                    var now = new Date().getTime();
                    var differenza = new Date(data['expiry_date']) - now;
                    var ore = Math.floor(differenza / (1000 * 60 * 60));
                    var minuti = Math.floor((differenza % (1000 * 60 * 60)) / (1000 * 60));
                    var secondi = Math.floor((differenza % (1000 * 60)) / 1000);


                    let expiration = ore + "h " + minuti + "m " + secondi + "s ";
                    setCountdownEl(expiration)

                    if (differenza < 0) {
                        clearInterval(intv);
                        setCountdownEl("Scaduto");
                    }
                })

                setCountdown(intv)
            }

            return () => {
                if (countdown) {
                    clearInterval(countdown)
                    setCountdown(null)
                }
            }
        }, [data, countdown])
        */

        if (type === "snowHeight") {
            return (
                <Marker position={[data.lat, data.lng]} icon={icon} ref={marker}>
                    <Tooltip direction="top" offset={new Point(0, -12)}>
                        {"locality" in data && <h5>{data["locality"]}</h5>}
                        <p>{"Altezza neve: " + data[type] + " cm"}</p>
                        <p>{"Accumulo 24H: " + data["accumulation24"] + " cm"}</p>
                        <p>{"Quota: " + data["altitude"] + " m"}</p>
                    </Tooltip>
                </Marker>
            )
        }

        if (type === "webcam" && data.lng && data.lat) {
            //console.log(data);
            return (
                <Marker position={[data.lat, data.lng]} icon={icon} ref={marker} eventHandlers={{
                    click: (e) => {
                        setWebcam(data);
                        setOverlay(prev => !prev);
                    },
                }}>
                </Marker>
            )
        }


        if (type === "reports" && data.lat && data.long) {
            return (
                <Marker position={[data.lat, data.long]} icon={icon} ref={marker}>
                    {
                        <Popup
                            direction="top"
                            offset={new Point(0, -12)}
                            onOpen={() => null}
                            onClose={() => console.log("closed")}
                            opacity={1}
                            className='report-popup-container'
                            autoClose={false}
                        >
                            <ReportImageComp popupData={data} />
                        </Popup>
                    }
                </Marker>
            )
        }


        return data[type] && data[type] !== "null" &&
            (
                <Marker position={[data.lat, data.lng]} icon={icon} ref={marker}>
                    <Popup>
                        <PopupBox popupInfo={data} />
                    </Popup>
                </Marker>
            )
    }

    return (
        <>
            {overlay && webcam &&
                <WebcamOverlay data={webcam} statusOverlay={setOverlay} />
            }
            <MapContainer
                center={[38.9054, 16.2894]}
                zoom={8}
                minZoom={8}
                maxZoom={15}
                scrollWheelZoom={true}
                id={"map"}
                whenCreated={setMap}
            >

                {calabria && <GeoJSON data={calabria} style={{ color: "#f7941e", fillOpacity: 0, weight: 2 }} />}
                { /* calabriaLabel && <GeoJSON data={calabriaLabel} style={{color: "#f7941e", fillOpacity: 0, weight: 2}} /> */}

                {dataLoaded && !showSnow && !showWebcam && !showReports &&
                    stations.map((el, index) => {
                        return el.status && <CustomMarker data={el} type={type} key={index} markerID={index} />
                    })
                }
                {
                    showSnow &&
                    (dataLoaded &&
                        snowData.map((el, index) => {
                            return <CustomMarker data={el} type={"snowHeight"} key={index} />
                        })
                    )
                }
                {
                    showWebcam &&
                    (
                        dataLoaded &&
                        webcamData.map((el, index) => {
                            return el.lng && el.lat && <CustomMarker data={el} type={"webcam"} key={index} />
                        })
                    )
                }
                {

                    showReports &&
                    (
                        reports.map((el, index) => {
                            return <CustomMarker data={el} type={"reports"} key={index} />
                        })
                    )

                }



                <div id="map-logo">
                    <img src="./img/logocwd2023.png" alt="mappa logo" width="128" />
                </div>
            </MapContainer>
            {
                connectionError &&
                <ErrorBanner text={"Mappa non disponibile"} />
            }
        </>
    )
}

function WebcamOverlay({ data, statusOverlay }) {

    const [loaded, setLoaded] = useState(false);

    return (
        <>
            <div id="img-overlay">
                <div className="img-overlay-container">
                    {"url" in data &&
                        <div className="img-overlay-box">
                            <img src={
                                data.url.startsWith("http://")
                                    ?
                                    process.env.REACT_APP_BACKEND + "/api/img?url=" + data.url
                                    :
                                    data.url

                            } onLoad={() => setLoaded(prev => !prev)} hidden={!loaded} />
                            {!loaded &&
                                <CircularProgress color="primary" style={{ alignSelf: 'center', paddingTop: "20px", paddingBottom: "20px" }} />
                            }

                            <span>
                                <p>{data.title}</p>
                                <a href={"./nowcasting/webcam/" + data._id}>Vai alla webcam {"\>"}</a>
                            </span>
                        </div>
                    }
                    <i className="fa fa-times-circle close-button" onClick={() => {
                        statusOverlay(prev => !prev);
                        setLoaded(prev => !prev);
                    }}></i>
                </div>
            </div>
        </>
    )
}

export default Map
