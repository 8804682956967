import React from 'react'
import RSSFeed from './RSSFeed'

import './Slider.css'

function Slider({data, children, isMobile}) {
    return (
        <div
            className='slider-container'
            style={{ backgroundImage: 'url("./img/slide2.jpg")' }}
        >
            {data && <div className='slider-content-row'>
                <div className="overlay-data">
                    <div className="data-container">
                        <div className="data-title">
                            <h3>DATI LIVE</h3>
                        </div>

                        <table className="data-table">
                            <tbody>
                                {"tempHigh" in data && "tempHighCity" in data && "tempHighID" in data &&
                                    <tr>
                                    <td className="param" style={{ color: 'red' }}>Località più calda</td>
                                    <td className="value">
                                        <b>{data.tempHigh}°c</b>
                                        {" a "}
                                        <a href={"/station/"+data.tempHighID}>{data.tempHighCity}</a>
                                    </td>
                                </tr>
                                }
                                {"tempMin" in data && "tempMinCity" in data && "tempMinID" in data &&
                                <tr>
                                    <td className="param" style={{ color: 'blue' }}>Località più fredda</td>
                                    <td className="value">
                                        <b>{data.tempMin}°c</b>
                                        {" a "}
                                        <a href={"/station/"+data.tempMinID}>{data.tempMinCity}</a>
                                    </td>
                                </tr>
                                }
                                {"tempAverage" in data &&
                                <tr>
                                    <td className="param">Temperatura media della regione</td>
                                    <td className="value"><b>{data.tempAverage}°c</b></td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="data-container">
                        <div className="data-title">
                            <h3>ESTREMI GIORNALIERI</h3>
                        </div>

                        <table className="data-table">
                            <tbody>
                                {"tempHighDaily" in data && "tempHighDailyCity" && "tempHighDailyID" in data &&
                                <tr>
                                    <td className="param" style={{ color: 'red' }}>Località più calda</td>
                                    <td className="value">
                                        <b>{data.tempHighDaily}°c</b>
                                        {" a "}
                                        <a href={"/station/"+data.tempHighID}>{data.tempHighDailyCity}</a>
                                    </td>
                                </tr>
                                }
                                {"tempMinDaily" in data && "tempMinDailyCity" && "tempMinDailyID" in data &&
                                <tr>
                                    <td className="param" style={{ color: 'blue' }}>Località più fredda</td>
                                    <td className="value">
                                        <b>{data.tempMinDaily}°c</b>
                                        {" a "}
                                        <a href={"/station/"+data.tempMinDailyID}>{data.tempMinDailyCity}</a>
                                    </td>
                                </tr>
                                }
                                {"maxRainTotal" in data && "maxRainTotalCity" && "maxRainID" in data &&
                                <tr>
                                    <td className="param">Località più piovosa</td>
                                    <td className="value">
                                        <b>{data.maxRainTotal} mm</b>{" a "}
                                        <a href={"/station/"+data.maxRainID}>{data.maxRainTotalCity}</a>
                                    </td>
                                </tr>
                                }
                                {"maxWind" in data && "maxWindCity" && "maxWindID" in data &&
                                <tr>
                                    <td className="param">Località più ventosa</td>
                                    <td className="value">
                                        <b>{data.maxWind} km/h</b>
                                        {" a "}
                                        <a href={"/station/"+data.maxWindID}>{data.maxWindCity}</a>
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*!isMobile && <RSSFeed />*/}
            </div>
            }
            {children}
        </div>
  )
}

export default Slider