import React, { useRef, useEffect, useState } from 'react'
import "./FileDragDrop.scss";

function FileDragDrop({ onUpload }) {

    const drop = useRef(null);
    const drag = useRef(null);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        if (drop.current) {
            drop.current.addEventListener('dragover', handleDragOver);
            drop.current.addEventListener('drop', handleDrop);
            drop.current.addEventListener("click", clickOnArea);

            drop.current.addEventListener('dragenter', handleDragEnter);
            drop.current.addEventListener('dragleave', handleDragLeave);

        }

        return () => {
            if (drop.current) {
                drop.current.removeEventListener('dragover', handleDragOver);
                drop.current.removeEventListener('drop', handleDrop);

                drop.current.removeEventListener('dragenter', handleDragEnter);
                drop.current.removeEventListener('dragleave', handleDragLeave);
            }
        };
    }, [drop]);

    const clickOnArea = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*'; // Specifica che accetta solo immagini
        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {                
                onUpload(selectedFile);
            }
        });
        fileInput.click(); // Simula il click sull'input file
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(true)

        if (e.target !== drag.current) {
            setDragging(true);
        }
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false)

        if (e.target !== drag.current) {
            setDragging(false);
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { files } = e.dataTransfer;

        if (files && files.length > 0) {
            onUpload(files[0]);
        }

        setDragging(false);
    };

    return (
        dragging ? (
            <div
                ref={drag}
                className='FilesDragAndDrop__placeholder'
            >
                Rilascia il file per caricare
                <span
                    role='img'
                    aria-label='emoji'
                    className='area__icon'
                >
                    <i className="fa fa-upload"></i>
                </span>
            </div>
        ) : (
            <div
                ref={drop}
                className='FilesDragAndDrop__area'
            >
                Trascina o seleziona un file
                <span
                    role='img'
                    aria-label='emoji'
                    className='area__icon'
                >
                    <i className="fa fa-image"></i>
                </span>
            </div>
        )
    )
}

export default FileDragDrop