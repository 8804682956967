import { TextField } from '@material-ui/core';
import React, {useState, useEffect} from 'react'
import { Button } from './Button';
import StationsItem from './StationsItem';

import "./StazioniMobile.css";

const provinceSlug = ["cs","kr","cz","vv","rc"];
const provinceList = ["cosenza","crotone","catanzaro","vibo valentia","reggio calabria"];

function StazioniMobile({jsonData}) {

    const [mobileProvinceNum, setMobileProvinceNum] = useState(0);
    const [province, setProvince] = useState({
        rc: [],
        vv: [],
        cz: [],
        kr: [],
        cs: []
    });
    const [loading, setLoading] = useState(true);


    //SEARCH
    const [inSearch, setInSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        provinceFilter(jsonData);
        setLoading(false);
        return () => {}
    }, []);

    const provinceFilter = async (json) => {
        provinceSlug.map((slug) =>{
            let filtered = json.filter((item)=>{
                return item.province === slug;
            });
            setProvince( prevState =>{
                prevState[slug] = filtered;
                return prevState;
            })
        });
    }
    
    const compareAZ = (a, b) => {
        var nameA = a.city.toUpperCase(); // ignora maiuscole e minuscole
        var nameB = b.city.toUpperCase(); // ignora maiuscole e minuscole
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    const compareZA = (a, b) => {
        var nameA = a.city.toUpperCase(); // ignora maiuscole e minuscole
        var nameB = b.city.toUpperCase(); // ignora maiuscole e minuscole
        if (nameA > nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }
        return 0;
    }
    
    const compareAltitude = (a, b) => {
        var altitudeA = parseInt(a.altitude); // ignora maiuscole e minuscole
        var altitudeB = parseInt(b.altitude); // ignora maiuscole e minuscole
        if (altitudeA > altitudeB) {
            return -1;
        }
        if (altitudeA < altitudeB) {
            return 1;
        }
        return 0;
    }

    const _searchEvent = ()=>{
        let filtered = jsonData.filter((item)=>{
            //console.log(item.name.toUpperCase(), search.toUpperCase());
            let locality = (item.hasOwnProperty("locality") && item.locality !== null) ? item.locality.toUpperCase() : "";
            return (item.city.toUpperCase()+locality).includes(search.toUpperCase());
        });
        //setAll(false);
        
        setSearchList(filtered);
        setInSearch(true);
    }

    return !loading && (
        <div className="mobile-wrapper">

            <div className="search-box">
                <TextField 
                    id="outlined-secondary"
                    label="Nome Stazione"
                    variant="outlined"
                    color="primary"
                    size="small"
                    className="search-box-input"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    fullWidth
                />
                <div className="search-button">
                    <Button buttonStyle={"btn--primary"} noRounded onClick={_searchEvent}>CERCA</Button>
                </div>
            
                {
                    inSearch &&
                    <div>
                        <Button buttonStyle={"btn--outline"} noRounded onClick={() => {
                            setInSearch(false);
                            setSearch("");
                        }}>
                            <i className="fas fa-undo"></i>
                        </Button>
                    </div>
                }
            </div>

            {
                inSearch && !loading &&
                <StationListSearch list={searchList}/>
            }

            <div className="mobile-titlebar">
                {
                    mobileProvinceNum > 0 &&
                    <Button onClick={() => setMobileProvinceNum(prevState => {
                        if (mobileProvinceNum >= 1) {
                            return prevState - 1;
                        }
                    })}>
                        <i className="fas fa-chevron-left"></i>
                    </Button>
                }

                {
                    //ELEMENT NULL
                    mobileProvinceNum === 0 && <div style={{ width: "70px" }}></div>
                }

                <p>{provinceList[mobileProvinceNum]}</p>

                {
                    mobileProvinceNum < 4 &&
                    <Button onClick={() => setMobileProvinceNum(prevState => {
                        if (mobileProvinceNum < 4) {
                            return prevState + 1;
                        }
                    })
                    }>
                        <i className="fas fa-chevron-right"></i>
                    </Button>
                }


                {
                    //ELEMENT NULL 
                    mobileProvinceNum === 4 && <div style={{ width: "100px" }}></div>
                }

            </div>

            

            {
                province[provinceSlug[mobileProvinceNum]].length > 0 &&
                <div className="mobile-filters">
                    <ButtonsSort
                        list={province[provinceSlug[mobileProvinceNum]]}
                        compareAZ={compareAZ} 
                        compareZA={compareZA}
                        compareAltitude={compareAltitude}
                    />
                </div>
            }
            {
                province[provinceSlug[mobileProvinceNum]].length > 0 &&
                <div className="mobile-list">
                    {
                        <StationList province={province[provinceSlug[mobileProvinceNum]]} />
                    }
                </div>
            }

            {
                province[provinceSlug[mobileProvinceNum]].length === 0 && !loading &&
                <div className="mobile-notfound">
                    <h4>Nessuna stazione caricata.</h4>
                </div>
            }
        </div>
    )
}


function StationListSearch({list}){
    return(
        <div className="search">
            <h3 className="search-title">Risultati ricerca: </h3>
            <ul className="province-list">          
                {
                    list.map((item)=>(
                    //<StationsItem key={item.key} city={item.city} url={item.url} type={item.type} id={item.id} locality={item.locality}/> 
                    <StationsItem obj={item} key={item.id}/>
                ))
                }
            </ul>
        </div>
    )
}

function StationList({province}){
    return(
        <ul className="province-list">          
            {
                province.map(item => <StationsItem obj={item} key={item.id}/>)
            }
        </ul>
    );
}


function ButtonsSort({list, compareAZ, compareZA, compareAltitude}){
    return(
        <div className="buttons-sort">
            <Button
                buttonStyle={"btn--black"}
                buttonSize={"btn--small"}
                onClick={() => list.sort(compareAZ)}
                noRounded
            >A-z <i className="fas fa-sort-amount-up"></i></Button>
            <Button
                buttonStyle={"btn--black"}
                buttonSize={"btn--small"}
                onClick={() => list.sort(compareZA)}
                noRounded
            >Z-a <i className="fas fa-sort-amount-down"></i></Button>
            <Button
                buttonStyle={"btn--black"}
                buttonSize={"btn--small"}
                onClick={() => list.sort(compareAltitude)}
                noRounded
            >
                <i className="fas fa-mountain"> </i>
            </Button>
        </div>
    )
}

export default StazioniMobile
