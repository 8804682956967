import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
//import { createTheme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import { FormControl } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { createTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import axios from 'axios';

import "./Stazioni.css";
import StationsItem from './StationsItem';
import { Button } from './Button';
import { CircularProgress, TableSortLabel, ThemeProvider } from '@material-ui/core';
import StazioniMobile from './StazioniMobile';
import ErrorBanner from './ErrorBanner';

import { Crypto } from '../scripts/crypto';
import Requests from '../scripts/Requests';

const provinceSlug = ["cs", "kr", "cz", "vv", "rc", "out"];

const theme = createTheme({
    palette: {
        primary: orange,
        secondary: {
            main: '#ffe0b2',
        },
    },
});

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 650,
    },
    tableContainer: {
        borderRadius: "20px 20px 0 0 !important"
    },
    tableHeader: {
    },
    tableHeaderCell: {
        backgroundColor: "#ffac33",
    },
    tableRow: {
        backgroundColor: "#ffe0b2"
    },
    tableCellLocality: {
        textDecoration: 'none'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
    if (isNaN(a[orderBy]) || a[orderBy] === "-" || a[orderBy] === "null") return 0;
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        //if (order !== 0) return order;
        //return a[1] - b[1];
        return order;
    });
    return stabilizedThis.map((el) => el[0]);
}

function tableSort(array, order, orderBy, filterBy) {
    if(filterBy !== 5){
        var province = provinceSlug.slice(0,5)
        array = array.filter(e => province.includes( e["province"] ))
    }

    if(filterBy !== null){
        if(filterBy !== 5) array = array.filter(e => e["province"] === provinceSlug[filterBy] )
    }
    
    const stringParameters = ["city", "province"]

    if (orderBy !== "status") {
        let defaultCompare = (a, b) => order === "asc" ? (parseFloat(a) - parseFloat(b)) : (parseFloat(b) - parseFloat(a));
        let stringCompare = (a, b) => order === "asc" ? a.localeCompare(b) : b.localeCompare(a);

        let r = array.filter(el => el[orderBy]).sort((a, b) => {
            return stringParameters.includes(orderBy) ? stringCompare(a[orderBy], b[orderBy]) : defaultCompare(a[orderBy], b[orderBy])
        });
        return [...r, ...array.filter(el => !el[orderBy])];
    } else {
        let booleanCompare = (a, b) => Number(a) - Number(b);
        let r = array.filter(el => el[orderBy]).sort((a, b) => booleanCompare(a[orderBy], b[orderBy]));
        let res = [...r, ...array.filter(el => !el[orderBy])];
        return order === "asc" ? res : res.reverse();
    }

}

function CustomTableHead(props) {
    const headCells = [
        { id: 'status', align: "center", disablePadding: false, label: 'Status', filter: true, idDOM: "table-status" },
        { id: 'altitude', align: "center", disablePadding: false, label: 'Altitudine (m)', filter: true },
        { id: 'city', align: "left", disablePadding: false, label: 'Località', filter: true },
        { id: 'province', align: "right", disablePadding: false, label: 'Provincia', filter: true },
        { id: 'temp', align: "right", disablePadding: false, label: 'Temperatura (°c)', filter: true },
        { id: 'tempLow', align: "right", disablePadding: false, label: 'Temp. Min (°c)', filter: true },
        { id: 'tempHigh', align: "right", disablePadding: false, label: 'Temp. Max (°c)', filter: true },
        { id: 'windSpeed', align: "right", disablePadding: false, label: 'Vento (km/h)', filter: true },
        { id: 'precipTotal', align: "right", disablePadding: false, label: 'Pioggia (mm)', filter: true },
        { id: 'update', align: "center", disablePadding: false, label: 'Ultimo Aggiornamento', filter: false }
    ];

    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        id={headCell.idDOM ? headCell.idDOM : ""}
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableHeaderCell}
                    >
                        {headCell.filter ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {<b>{headCell.label}</b>}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>) :
                            (<b>{headCell.label}</b>)
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

CustomTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number,
};

const filterButtonStyle = (e, idx) => {
    e.target.classList.toggle("btn--outline")
    e.target.classList.toggle("btn--primary")
    
    provinceSlug.forEach(slug => {
        if (slug !== provinceSlug[idx] && slug !== "out") {
            document.getElementById("webcam-" + slug).classList.remove("btn--outline")
            document.getElementById("webcam-" + slug).classList.add("btn--primary")
        }
    });
}


export default function Stazioni() {
    const classes = useStyles();

    const [stations, setStations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [connectionError, setConnectionError] = useState(false);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    //MOBILE
    const [isMobile, setIsMobile] = useState(false);

    //SEARCH
    const [inSearch, setInSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [stationsSearched, setStationsSearched] = useState([]);

    //FILTER
    const [filterBy, setFilterBy] = useState(null);
    //const []

    useEffect(() => {


        Requests.get_stations_live()
            .then(stations => {
                //let stations = JSON.parse(Crypto.decode(res.data));
                setStations( shuffle(stations) );
                setLoading(false);
            })
            .catch(err => {
                setConnectionError(prev => !prev);
            });


        //MOBILE CHECK    
        if (window.innerWidth < 960) {
            setIsMobile(true);
        }
        window.addEventListener("resize", handleResize);

        return () => { }
    }, []);


    useEffect(()=>{
        if(!loading){
            setTimeout(()=>{
                document.getElementById("table-status").getElementsByTagName("span")[0].click()
            }, 500)
        } 
    },[loading])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleResize = () => {
        if (window.innerWidth < 960) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    const searchEvent = () => {
        setInSearch(true);
        let filtered = stations.filter(e => {
            if (e.locality !== null) {
                return (e.locality.toUpperCase() + e.city.toUpperCase()).includes(search.toUpperCase());
            } else {
                return e.city.toUpperCase().includes(search.toUpperCase());
            }
        });
        setStationsSearched(filtered);
    }

    return loading ?
        (
            connectionError ?
                <ErrorBanner text="Riprova più tardi..." />
                :
                <div className={"progress-wrapper"}><CircularProgress size={18} /></div>
        ) : (
            !isMobile ?
                (
                    <>
                        <div className="search-box-desktop">
                            <FormControl fullWidth style={{ marginRight: "10px" }}>
                                <TextField
                                    onChange={(e) => setSearch(e.target.value)}
                                    id="outlined-basic"
                                    label="Scrivi il nome di una località..."
                                    variant="outlined"
                                    onKeyPress={(e) => e.key === "Enter" ? document.getElementById("search-button").click() : null}
                                />
                            </FormControl>
                            <Button id="search-button" buttonSize="btn--large" buttonStyle="btn--primary" onClick={searchEvent}>CERCA</Button>
                            {inSearch &&
                                <Button buttonStyle="btn--outline" buttonSize="btn--large" onClick={() => setInSearch(false)}>
                                    <i className="fas fa-undo"></i>
                                </Button>
                            }
                        </div>
                        
                        <FilterBar isMobile={isMobile} filterBy={filterBy} setFilterBy={setFilterBy}/>

                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="simple table">

                                <CustomTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />


                                <TableBody>
                                    {
                                        tableSort(inSearch ? stationsSearched : stations, order, orderBy, filterBy)
                                            .map((el, idx) => (
                                                //stations.map((el, idx) => (
                                                <TableRow key={idx} className={classes.tableRow}>
                                                    <TableCell align="center" >
                                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center" }}>
                                                            {el.status ? <div className="statusOn"></div> : <div className="statusOff"></div>}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="center">{el.altitude ? el.altitude : "-"}</TableCell>
                                                    <TableCell className={classes.tableCellLocality}>
                                                        <a href={"/station/" + el.id} className="localityLink">
                                                            <p >{el.city ? el.city : ""}</p>
                                                            <p style={{ fontStyle: "italic" }}>{el.locality ? el.locality : ""}</p>
                                                        </a>
                                                    </TableCell>
                                                    <TableCell align="center">{el.province ? el.province.toUpperCase() : "-"}</TableCell>
                                                    <TableCell align="right">{el.temp ? el.temp : "-"}</TableCell>
                                                    <TableCell align="right" style={{ color: "blue" }}>{el.tempLow ? el.tempLow : "-"}</TableCell>
                                                    <TableCell align="right" style={{ color: "red" }}>{el.tempHigh ? el.tempHigh : "-"}</TableCell>
                                                    <TableCell align="right" style={{ color: "cadetblue" }}>{el.windSpeed ? el.windSpeed : "-"}</TableCell>
                                                    <TableCell align="right" style={{ color: "blueviolet" }}>{el.precipTotal ? el.precipTotal : "-"}</TableCell>
                                                    <TableCell align="center">
                                                        <p>{el.updateDate ? el.updateDate : ""}</p>
                                                        <p>{el.updateTime ? el.updateTime.substr(0, 5) : ""}</p>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </>
                )
                :
                <StazioniMobile jsonData={stations} />
        );
}



const FilterBar = ({isMobile, filterBy, setFilterBy}) => {
    return (
        <div className="province-list-filters">
            <ul>
                <li id="pf-0">
                    <Button id={"webcam-cs"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded

                        onClick={(e) => {
                            if (!isMobile) {
                                if(filterBy === 0) setFilterBy(null);
                                else setFilterBy(0);
                                filterButtonStyle(e, 0);
                            }
                        }}

                        onTouchStart={e => {
                            if(filterBy === 0) setFilterBy(null);
                            else setFilterBy(0);
                            filterButtonStyle(e, 0);
                        }}

                        fullWidth={isMobile}
                        noLink
                        disableHover
                    >
                        COSENZA
                    </Button>
                </li>
                <li id="pf-1">
                    <Button id={"webcam-kr"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded
                        onClick={(e) => {
                            if (!isMobile) {
                                if(filterBy === 1) setFilterBy(null);
                                else setFilterBy(1);
                                filterButtonStyle(e, 1);
                            }
                        }}

                        onTouchStart={e => {
                            if(filterBy === 1) setFilterBy(null);
                            else setFilterBy(1);
                            filterButtonStyle(e, 1);
                        }}

                        fullWidth={isMobile}
                        noLink
                        disableHover
                    >
                        CROTONE
                    </Button>
                </li>
                <li id="pf-2">
                    <Button id={"webcam-cz"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded
                        onClick={(e) => {
                            if (!isMobile) {
                                if(filterBy === 2) setFilterBy(null);
                                else setFilterBy(2);
                                filterButtonStyle(e, 2);
                            }
                        }}

                        onTouchStart={e => {
                            if(filterBy === 2) setFilterBy(null);
                                else setFilterBy(2);
                            filterButtonStyle(e, 2);
                        }}

                        fullWidth={isMobile}
                        noLink
                        disableHover
                    >
                        CATANZARO
                    </Button>
                </li>
                <li id="pf-3">
                    <Button id={"webcam-vv"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded
                        onClick={(e) => {
                            if (!isMobile) {
                                if(filterBy === 3) setFilterBy(null);
                                else setFilterBy(3);
                                filterButtonStyle(e, 3);
                            }
                        }}

                        onTouchStart={e => {
                            if(filterBy === 3) setFilterBy(null);
                            else setFilterBy(3);
                            filterButtonStyle(e, 3);
                        }}

                        fullWidth={isMobile}
                        noLink
                        disableHover
                    >
                        VIBO VALENTIA
                    </Button>
                </li>
                <li id="pf-4">
                    <Button id={"webcam-rc"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} noRounded
                        onClick={(e) => {
                            if (!isMobile) {
                                if(filterBy === 4) setFilterBy(null);
                                else setFilterBy(4);
                                filterButtonStyle(e, 4);
                            }
                        }}

                        onTouchStart={e => {
                            if(filterBy === 4) setFilterBy(null);
                            else setFilterBy(4);
                            filterButtonStyle(e, 4);
                        }}

                        fullWidth={isMobile}
                        noLink
                        disableHover
                    >
                        REGGIO CALABRIA
                    </Button>
                </li>
                <li id="pf-5">
                    <Button id={"webcam-out"} buttonStyle={"btn--primary"} buttonSize={"btn--small"} style={{marginLeft: "50px"}} noRounded
                        onClick={(e) => {
                            if (!isMobile) {
                                if(filterBy === 5) setFilterBy(null);
                                else setFilterBy(5);
                                filterButtonStyle(e, 5);
                            }
                        }}

                        onTouchStart={e => {
                            if(filterBy === 5) setFilterBy(null);
                            else setFilterBy(5);
                            filterButtonStyle(e, 5);
                        }}

                        fullWidth={isMobile}
                        noLink
                        disableHover
                    >
                        FUORI REGIONE
                    </Button>
                </li>
            </ul>
        </div>
    )
}