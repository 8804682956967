import React, { useState, useEffect } from 'react'
import { CircularProgress, RadioGroup, FormControlLabel, Radio, TextField, Select, MenuItem, FormControl, FormHelperText, Checkbox } from '@material-ui/core'
import Parallax from "../components/Parallax"

import ReCAPTCHA from "react-google-recaptcha";

import "./Aderisci.css"
import { Button } from '../components/Button'

import ReactGA from 'react-ga4';
import { validateEmail } from '../scripts/functions';
import Requests from '../scripts/Requests';

function Aderisci() {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.search, title: "Aderisci" });
        return null;
    }, []);

    const captcha = true;

    //CHECK FORM
    const [form, setForm] = useState({
        name: undefined,
        surname: undefined,
        email: undefined,
        platform: undefined,
        city: undefined,
        privacy: undefined,
        captcha: undefined
    });
    const [emailError, setEmailError] = useState(false);
    const [inError, setInError] = useState(false);

    const SOFTWARE = ["cumulus", "weatherdisplay", "weatherlink"];
    const requiredInputs = ["name", "surname", "email", "platform", "city", "privacy"]

    const [loading, setLoading] = useState(false);
    const [sended, setSended] = useState(false);


    function checkForm() {
        let wrongInputs = []
        Object.entries(form).forEach(e => {
            if (requiredInputs.includes(e[0])) {
                if (e[0] === "email") {
                    if (!validateEmail(form.email)) {
                        setEmailError(true);
                        wrongInputs.push(e[0]);
                    } else {
                        setEmailError(false);
                    }
                }
                if ((e[1] === undefined || e[1] === "" || e[1] === false) && e[0] !== "email") {
                    wrongInputs.push(e[0])
                }
            }
        })

        if (wrongInputs.length === 0) return true;
        setInError(true);
        return false
    }

    function sendData() {

        if(checkForm()){
            setLoading(true);

            Requests.add_adhesion(form)
            .then( () => setSended(true))
            .catch(err => setInError(true))
            /*
            axios.post(process.env.REACT_APP_BACKEND + "/aderisci", form)
                .then( () => setSended(true))
                .catch(err => setInError(true))
            */
        }else{
            console.error("Errore compilazione form");
            setInError(true);
        }
    }

    const platformPlaceholder = () => {
        switch (form.platform) {
            case "wunderground":
                return "Inserisci id stazione o url di Wunderground API"
            case "netatmo":
                return "Inserisci id stazione caricata su Netatmo"
            case "other":
                return "Specifica il sito web o la piattaforma utilizzata"
            default:
                return "Inserisci il sito web a cui fa riferimento la tua stazione"
        }
    }

    function updateForm(property, value) {
        if (emailError) setEmailError(false);
        setForm((prev) => {
            let obj = { ...prev };
            obj[property] = value;
            return obj;
        });
    }


    return !loading ? (
        <form className="aderisci-wrapper" >
            <Parallax img="../img/slide3.jpg" position="center" />
            <div className="aderisci-title">
                <h3>ADERISCI ALLA RETE</h3>
                <h5>Contribuisci anche tu a far crescere il nostro progetto.</h5>
            </div>

            <hr className="separator" />

            <div className="aderisci-datipersonali">
                <label className="aderisci-input">
                    Nome:
                    <TextField
                        id="standard-basic"
                        placeholder="Nome"
                        variant="outlined"
                        size="small"
                        error={inError && !form.name}
                        //value={nome}
                        onChange={(e) => updateForm("name", e.target.value)}
                    />
                </label>

                <label className="aderisci-input">
                    Cognome:
                    <TextField
                        id="standard-basic"
                        placeholder="Cognome"
                        variant="outlined"
                        size="small"
                        error={inError && !form.surname}
                        onChange={(e) => updateForm("surname", e.target.value)}
                    />
                </label>
                <label className="aderisci-input">
                    Email:
                    <TextField
                        id="standard-basic"
                        placeholder="Email"
                        variant="outlined"
                        type="email"
                        size="small"
                        fullWidth
                        error={inError && emailError}
                        onChange={(e) => updateForm("email", e.target.value)}
                    />
                </label>
                <label className="aderisci-input">
                    CELLULARE (Facoltativo):
                    <TextField
                        id="standard-basic"
                        placeholder="Cellulare"
                        variant="outlined"
                        type="tel"
                        size="small"
                        onChange={(e) => updateForm("cell", e.target.value)}
                    />
                </label>

            </div>

            <hr className="separator" />

            <div className="aderisci-strumentazione">
                <label className="aderisci-input">
                    STRUMENTAZIONE:
                    <TextField
                        id="standard-basic"
                        placeholder="Strumentazione"
                        variant="outlined"
                        size="small"
                        error={inError && !form.stationName}
                        onChange={(e) => updateForm("stationName", e.target.value)}
                    />
                </label>

                <div className="aderisci-input">
                    <FormControl error={inError}>
                        <RadioGroup
                            aria-label="strumentazione"
                            name="strumentazione"
                            onChange={(e) => updateForm("platform", e.target.value)}
                        >
                            <label className="aderisci-input">SCEGLI SOFTWARE/API UTILIZZATO:
                                <span>
                                    <FormControlLabel value="cumulus" control={<Radio color="primary" />} label="Cumulus" />
                                    <FormControlLabel value="weatherlink" control={<Radio color="primary" />} label="Weatherlink" />
                                    <FormControlLabel value="weatherdisplay" control={<Radio color="primary" />} label="WeatherDisplay" />
                                    {/*<FormControlLabel value="weatherlink-api" control={<Radio color="primary"/>} label="Weatherlink API" />*/}
                                    <FormControlLabel value="wunderground" control={<Radio color="primary" />} label="Wunderground API" />
                                    <FormControlLabel value="netatmo" control={<Radio color="primary" />} label="Netatmo" />
                                    <FormControlLabel value="other" control={<Radio color="primary" />} label="Altro (specificare)" />
                                    <FormHelperText>{inError && !form.platform ? "Scegli un piattaforma" : null}</FormHelperText>
                                </span>
                            </label>
                        </RadioGroup>
                    </FormControl>
                </div>

                <label className="aderisci-input">
                    {form.platform === "wunderground" && "ID STAZIONE O URL:"}
                    {form.platform === "other" && "ALTRO:"}
                    {form.platform === "netatmo" && "ID STAZIONE:"}
                    {(SOFTWARE.includes(form.platform) || !form.platform) && "SITO WEB:"}
                    <TextField
                        id="standard-basic"
                        placeholder={platformPlaceholder()}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => updateForm("website", e.target.value)}
                        error={inError && !form.website}
                    />
                </label>

            </div>

            <hr className="separator" />

            <div className="aderisci-localita">
                <label className="aderisci-input">
                    Comune:
                    <TextField
                        id="standard-basic"
                        placeholder="Comune"
                        variant="outlined"
                        size="small"
                        error={inError && !form.city}
                        onChange={(e) => updateForm("city", e.target.value)}
                    />
                </label>
                <label className="aderisci-input">
                    Località/Frazione (Facoltativo):
                    <TextField
                        id="standard-basic"
                        placeholder="Località"
                        variant="outlined"
                        size="small"
                        onChange={(e) => updateForm("locality", e.target.value)}
                    />
                </label>
                <label className="aderisci-input">
                    Quota:
                    <TextField
                        id="standard-basic"
                        placeholder="Quota"
                        variant="outlined"
                        size="small"
                        onChange={(e) => updateForm("quote", e.target.value)}
                    />
                </label>
                <label className="aderisci-input">

                    Tipo di installazione:
                    <FormControl>
                        <Select
                            placeholder="Scegli ubicazione"
                            id="select"
                            defaultValue={"not-specified"}
                            onChange={(e) => updateForm("position", e.target.value)}
                        >
                            <MenuItem value="not-specified">NON SPECIFICATA</MenuItem>
                            <MenuItem value={"urbana"}>URBANA</MenuItem>
                            <MenuItem value={"semiurbana"}>SEMIURBANA</MenuItem>
                            <MenuItem value={"extraurbana"}>EXTRAURBANA</MenuItem>
                        </Select>
                    </FormControl>
                </label>
                <label className="aderisci-input">
                    Microclima:
                    <TextField
                        id="standard-basic"
                        placeholder="Descrivi il microclima"
                        variant="outlined"
                        size="small"
                        multiline
                        rows={4}
                        onChange={(e) => updateForm("microclimate", e.target.value)}
                    />
                </label>
            </div>
            <hr className="separator" />
            <div className="check-privacy">
                <h4>Consenso al trattamento dei dati personali</h4>

                <FormControlLabel
                    control={
                        <Checkbox
                            name="checkedB"
                            color="primary"
                            onChange={(e) => updateForm("privacy", e.target.checked)}
                        />
                    }
                    style={{ textAlign: 'left' }}
                    label={<>Dichiaro di aver compiuto i 18 anni di età, di aver letto l'informativa sul trattamento dei dati personali e di prestarne il consenso. <a href="./privacy-policy" style={{ color: "#f7941e" }}>Leggo l'informativa</a></>}
                />
                {
                    inError && !form.privacy &&
                    <FormHelperText style={{ color: "red" }}>Accettare l'informativa sui dati.</FormHelperText>
                }
            </div>
            { 
            captcha &&
                <div className="recaptcha">
                    <ReCAPTCHA
                        sitekey="6LcrxjoaAAAAAKv-T898uCB-dMS-PYz_LJD7bIM6"
                        onChange={(e) => updateForm("captcha", true)}
                    />
                </div>
                
            }


            {
                (form.captcha || !captcha) &&
                <div className="aderisci-send">

                    <Button
                        buttonStyle="btn--primary"
                        onClick={() => sendData()}
                    >
                        INVIA
                    </Button>
                </div>

            }


        </form>


    ) : (
        <div className="contatti-wrapper">
            {
                inError ?
                <>
                    <h3>ERRORE</h3>
                    <p>Impossibile inviare i dati... <br/>Risolveremo al più presto!</p>
                </>
                :
                <>
                {sended &&
                    <>
                        <h3>Grazie di aver aderito alla nostra rete!</h3>
                        <p>Ti contatteremo nel più breve tempo possibile...</p>
                    </>
                }
                {!sended && <CircularProgress size={48} color="primary" />}
                </>
            }
            
        </div>
    )
}


export default Aderisci
