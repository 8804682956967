import React, { useEffect } from 'react'
import { SocialIcon } from 'react-social-icons'
import { Grid } from '@material-ui/core'
import { } from '@material-ui/core'
import './Footer.css'
import Requests from '../scripts/Requests'
import { useState } from 'react'

function Footer() {

    const [partners, setPartners] = useState([])

    useEffect(() => {
        Requests.get_partners()
            .then(res => {
                setPartners(res)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <div className="footer">

            {partners && partners.length > 0 &&
                <>
                    <div className="footer-row">
                        <h3 id="collaborazioni-title">In collaborazione con</h3>
                    </div>

                    <div className='footer-content'>
                        <Grid container spacing={5} alignItems={"center"}>
                            {
                                partners.map((el, idx) => {
                                    return (
                                        <Grid item sm={3} key={idx}>
                                            <a href={el.url} target="_blank">
                                                <img src={process.env.REACT_APP_BACKEND + el.banner} width="100%" alt={el.name} />
                                            </a>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </div>
                </>
            }
            <div className="footer-row">
                <span className="info">
                    <p className="copyright">© Copyright {new Date().getFullYear()}</p>
                    <p className="spacing">-</p>
                    <p className="author">Calabria Weather Data</p>
                </span>
                <span className="social-icons">
                    <SocialIcon url={process.env.REACT_APP_FACEBOOK} network="facebook" fgColor="#fff" style={{ height: 32, width: 32 }} />
                    <SocialIcon url={process.env.REACT_APP_INSTAGRAM} network="instagram" fgColor="#fff" style={{ height: 32, width: 32 }} />
                </span>
                <div className="policy">
                    <a
                        target='_blank'
                        href={process.env.REACT_APP_BACKEND + "/api/privacy_policy"}
                    >Informativa Privacy
                    </a>

                    <a
                        href="/altre-informative"
                    >Altre Informative</a>
                </div>
            </div>
        </div>
    )
}

export default Footer
