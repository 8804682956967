import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {CircularProgress} from '@material-ui/core'
import './App.css';

import ReactGA from 'react-ga4';

import Footer from './components/Footer'
import Navbar from './components/Navbar'
import Contatti from './routes/Contatti';
import Home from './routes/Home';
import ChiSiamo from './routes/ChiSiamo';
import Nowcasting from './routes/Nowcasting';
import Privacy from './routes/informative/Privacy';
import Informativa from './routes/informative/Informativa';
import Aderisci from './routes/Aderisci';
import Radar from './routes/nowcasting/Radar';
import Satelliti from './routes/nowcasting/Satelliti';
import Webcam from './routes/nowcasting/Webcam';
import Nivometria from './routes/nowcasting/Nivometria'

import './App.css'
import Map from './routes/Map';
import StationPage from './components/StationPage';
import WebcamPage from './components/WebcamPage';
import NotFound from './routes/NotFound';

import { Crypto } from './scripts/crypto';
import Requests from './scripts/Requests';
import Report from './routes/Report';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID,{});

function App() {
  const [loading, setLoading] = useState(true);
  const [maintenance, setMaintenance] = useState(false);
  const enableWhitelist = true;

  async function getClientIP(){      
    //let res = await axios.get(process.env.REACT_APP_BACKEND + "/client");
    let res = await axios.get("https://api.ipify.org/?format=json");
    let ip = await res.data.ip;
    return ip;
  }

  async function getMaintenanceStatus(){
    return await fetch(process.env.REACT_APP_BACKEND + "/maintenance")
      .then(res => res.text())
      .then(res => res.includes("true") ? true : false)
      .catch(err => console.error(err));
  }

  async function getWhitelist(){
    return await fetch(process.env.REACT_APP_BACKEND + "/whitelist")
      .then(res => res.text())
      .then(res => JSON.parse(Crypto.decodeWithKey(res, 2)))
      .catch(err => console.error(err));
  }


  useEffect(()=>{
      //ReactGA.pageview(window.location.pathname + window.location.search);
      if(process.env.REACT_APP_MAINTENANCE === "true"){
        Requests.get_maintenance()
        .then(status => {

          setMaintenance(status)
          setLoading(false)
          
        })
        .catch(err => {
          console.log(err);
          setMaintenance(false);
          setLoading(false);
        })
      }else{
        setLoading(false)
        setMaintenance(false)
      }

      /*
      getMaintenanceStatus()
      .then(async status => {
          if(status){
            let ip = await getClientIP();
            let whitelist = await getWhitelist();
            
            if(!whitelist.some(el => el.ipAddress === ip) && enableWhitelist){
              console.log(ip, whitelist);
              setMaintenance(true);
            }
            setLoading(false);
          }else{
            setLoading(false);
            setMaintenance(false);
          }
      })
      .catch(err => {
        console.log(err);
        setMaintenance(true);
        setLoading(false);
      })
      */

      if(window !== undefined) setLoading(false); //MANUTENZIONE OFF
      return null;
  },[]);

  return loading ? 
    ( 
      <div style={{display: "flex", justifyContent:'center', alignItems:'center', minHeight: "100vh"}}>
        <CircularProgress color="primary" size={48}/>
      </div>
      
    ):(
      maintenance
      ? 
      (
        <div className="maintenence-image" style={{backgroundImage: 'url("../img/manutenzione.jpg")'}}>
          <img src="../img/logo.png" width="400" height="168"/>
          <h2 className="maintenence-title">Manutenzione in corso</h2>
        </div>
      ):(
        <>
        <Router>
          <Navbar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/chi-siamo" exact component={ChiSiamo}/>
            <Route path="/nowcasting" exact component={Nowcasting} />
              <Route path="/nowcasting/radar" exact component={Radar} />
              <Route path="/nowcasting/satelliti" exact component={Satelliti} />
              <Route path="/nowcasting/webcam" exact component={Webcam} />
              <Route path="/nowcasting/webcam/:id" exact children={<WebcamPage />}  />
              {/*<Route path="/nowcasting/nivometria" exact component={Nivometria} /> */}
            <Route path="/mappa" exact component={Map}/>
            <Route path="/contatti" exact component={Contatti}/>
            <Route path="/privacy-policy" exact component={Privacy}/>
            <Route path="/altre-informative" exact component={Informativa}/>
            <Route path="/aderisci" exact component={Aderisci}/>
            <Route path="/station/:id" exact children={<StationPage />}  />
            <Route path="/report" exact component={Report} />
            <Route component={NotFound}/>
          </Switch>
          <Footer />
        </Router>
        </>
      )
    )
  
}

export default App;
//ReactDOM.render(<App />, document.getElementById('root'));